import * as THREE from 'three';
import Application from './Application.js';
import ImagesData from './ImagesData.js';
import CarouselImage from './CarouselImage.js';
import Pointer from './Pointer.js'
import { gsap } from "gsap";
import url from 'url-parameters';
import EventEmitter from './Utils/EventEmitter.js'

export const Carousel_STATES =  { // constants for view states
    INACTIVE:0,
    THUMBNAILS: 1,
    ZOOM: 2
};
export default class Carousel extends EventEmitter
{
    constructor()
    {
        super();

        this.app = new Application();
        this.params = this.app.configuration.parameters;
        this.resources = this.app.resources
        this.scene = this.app.scene;

        this.active = true;                     // enable/diable interaction
        this.STATES = Carousel_STATES;
        this.status = this.STATES.INACTIVE;
        
        this.imagesData = new ImagesData();     // list of images
        this.imageGroup = new THREE.Group();    // thumbnails group of meshes
        this.inclineOnZoom = []                 // the objects in this array will be rotated on zoom
        this.loadedThumbnails = []              // keep track of the loaded thumbnails
        this.lastItemPosition = null            // keep track of previous position of zoomed image
        
        this.lastGalleryType = null;            // keep previous requests
        this.lastGalleryId = null;
        this.lastCarouselId = null;
        this.carouselId = null;                 // current image id to zoom

        this.mainAnimation = gsap.timeline({    // shuffle thumbnails animation
            autoRemoveChildren: true,
            onComplete: () => {
                // after the shuffle is complete
                if (this.imagesData.list.length > 0)
                    this.trigger('shuffled');       // send event to alert that new images are ready (on the first call the list is empty, we don't send the event)
                this.showThumbnails();
                this.toggleShortDescription(true);
                // we can click again
                this.pointer.active = true;
                // check if we need to zoom an image
                this.zoomRequestHandler();
            }
        });  
        this.mainAnimation.add(()=>{ },"+="+this.params.imageOutInitialDelay); // adds a dummy action to wait before start        
        this.reverseAnimation = gsap.timeline({ // reverse animation: current images return to center
            autoRemoveChildren: true,
            onComplete: () => {
                // after reverse animation 
                // reset the thumbnails and reload
                this.imagesData.reset();
                this.loadedThumbnails = [];
                this.resetZoomedImage();
                this.imagesData.load(); // this will call onDataReload() when completed
            }
        });

        this.dragging = false;                      // true while user drags
        this.draggingStart = new THREE.Vector2();   // start of dragging
        this.draggingDirection = null;              // store direction of drag

        this.pointer = new Pointer();   // keeps track of image under the mouse
        this.pointer.on('click', () => { this.click() });
        this.pointer.on('move', () =>  { this.move()  });
        this.pointer.on('down', () =>  { this.down()  });
        this.pointer.on('up', () =>  { this.up()  });
        this.pointer.on('pinch_change', () =>  { this.pinch_change()  });
        
        this.zoomedItemId = null;       // contains zoomed image id, when zoom active
        this.zoomScaleRatio = null;     // base scale ratio of zoomed image
        this.imagesScale = 1;           // scale of images

        this.groupRotationAnimation = null;         // animation of the group of images rotating
        this.groupRotationCounterAnimations = [];   // array of animations of each image counter rotating

        this.setGroup();
        this.setFadePlane();
        this.setDescriptions();

        // Images loaded event
        url.enable(() => 
        {
            this.onUrlChange();
        }, false);
        this.imagesData.on('loaded', () =>
        {
            this.onDataReload();
            this.trigger('loaded');
        });    
        this.imagesData.on('error', () =>
        {
            this.onDataError();
            this.trigger('error');
        });      
    }

    /**
     * First setup of the main group of meshes
     */
    setGroup() {
        this.imageGroup = new THREE.Group();      
        // animation: continuous rotation
        this.groupRotationAnimation = gsap.timeline();
        this.groupRotationAnimation.to(this.imageGroup.rotation,{
            duration:this.params.imagesCircleRotationDuration,
            z: (this.params.imagesCircleRotationDirection)*2* Math.PI,
            ease:"none",
            repeat:-1
        });

        this.scene.add(this.imageGroup); 
    }

    /**
     * First setup of plane that hides images behind the zoom image
     */
    setFadePlane() {
        var fadePlane = new THREE.PlaneGeometry(100,100);
        var fadePlaneMaterial = new THREE.MeshBasicMaterial({
            color: this.params.fullImage.fadePlaneColor,        
        })
        if (this.resources.items.fadePlaneOpacity) {
            var texture = this.resources.items.fadePlaneOpacity;
            texture.encoding = THREE.sRGBEncoding;
            texture.wrapS = THREE.RepeatWrapping
            texture.wrapT = THREE.RepeatWrapping
            fadePlaneMaterial.alphaMap = texture;
            fadePlaneMaterial.transparent = true;
        }
        this.fadePlane = new THREE.Mesh(fadePlane, fadePlaneMaterial)
        this.fadePlane.receiveShadow = false;
        this.fadePlane.castShadow = false;
        this.fadePlane.position.z = this.params.fullImage.position.z - 0.01;
        this.fadePlane.visible = false;
        this.scene.add(this.fadePlane);    
    }


    /**
     * create HTML containers for descriptions
     */
    setDescriptions() {
        this.shortDescriptionContainer = document.createElement("div");
        this.shortDescriptionContainer.className = this.params.shortDescriptionClass;
        document.body.appendChild(this.shortDescriptionContainer);
        this.toggleShortDescription(false);

        this.longDescriptionContainer = document.createElement("div");
        this.longDescriptionContainer.className = this.params.longDescriptionClass;
        document.body.appendChild(this.longDescriptionContainer);
        this.toggleLongDescription(false);
    }

    /**
     * Happens after every image list reloading. 
     * Refresh carousel with new images
     */
    onDataReload() {
        // no images: shouldn't happen
        if (this.imagesData.list.length == 0)
            return;

        // destroy old images, restart
        this.pointer.active = false;      // deactivate input
        this.reverseAnimation.revert();
        this.mainAnimation.revert();
        this.disposeOfChildren(this.imageGroup);
        this.groupRotationAnimation.restart();

        // camera animations
        this.mainAnimation.add(()=>{
            this.app.camera.moveAnimation(true);
         },"<"); // move the camera inside
        this.reverseAnimation.add(()=>{
            this.app.camera.moveAnimation(true);
        },"<"); // move the camera inside
        

        // populate carousel with images

        // calculate image dispersion in 2-3 circle sections:
        var sectionsCount = this.params.imagesCircleSections;
        if ((this.imagesData.list.length < 10 && sectionsCount > 2) || this.params.imagesCircleSections > this.imagesData.list.length)
            sectionsCount = 2; // few images, use only two sections
        var allSectionsSize = this.params.imagesCircleOuterRadius-this.params.imagesCircleInnerRadius;
        var allSectionsArea = 2*Math.PI*Math.pow(this.params.imagesCircleOuterRadius,2) - 2*Math.PI*Math.pow(this.params.imagesCircleInnerRadius,2);
        var sectionsSize = allSectionsSize/sectionsCount;
        var sections = Array(sectionsCount);
        // console.log(this.imagesData.list,sectionsCount)

        for (var j = 0; j < sectionsCount; j++) {
            sections[j] = {
                innerRadius: this.params.imagesCircleInnerRadius + j*sectionsSize,
                outerRadius: this.params.imagesCircleInnerRadius + (j+1)*sectionsSize,
            }
            sections[j].area = 2*Math.PI*Math.pow(sections[j].outerRadius,2) - 2*Math.PI*Math.pow(sections[j].innerRadius,2);
            sections[j].areaRatio = sections[j].area / allSectionsArea;
            // sections[j].areaFactor = sections[j].areaRatio / sections[0].areaRatio;
            // sections[j].angle = 2*Math.PI * sections[j].areaRatio;
            sections[j].imagesCapacity = Math.ceil(this.imagesData.list.length * sections[j].areaRatio);
            sections[j].imagesCount = 0;
            
            // sections[j].imageStep = Math.ceil(this.imagesData.list.length / sections[j].imagesCapacity);
            for (var n = 0; n < sections[j].imagesCapacity; n++) {
                // find the correct section for the images
                var imId = Math.floor( j + n*this.imagesData.list.length / sections[j].imagesCapacity); // this should be the id
                if (this.imagesData.list[imId]=== undefined)
                    continue;
                if (this.imagesData.list[imId].section === undefined)
                    this.imagesData.list[imId].section = j;     // if free, use it
                else if (this.imagesData.list[imId-1].section == undefined)
                    this.imagesData.list[imId-1].section = j;   //try the position below
                else if (imId+1 < this.imagesData.list && this.imagesData.list[imId+1].section == undefined)
                    this.imagesData.list[imId+1].section = j;     //try the position after                
            }
        }
        
        // angle between images
        var angle = 2*Math.PI / this.imagesData.list.length; 
        
        // correct image size when few images
        this.imagesScale = 1;
        if (this.params.imageSizeFewerThreshold > 0 && 
            this.imagesData.list.length < this.params.imageSizeFewerThreshold) {                
                this.imagesScale = this.params.imageSizeFewerLowerFactor + (this.params.imageSizeFewerUpperFactor - this.params.imageSizeFewerLowerFactor)/(this.params.imageSizeFewerThreshold - this.imagesData.list.length);
        }

        for (var i=0; i < this.imagesData.list.length; i++) {
            
            var currentSection = sections[this.imagesData.list[i].section ?? sectionsCount-1];
            var randomRadius = Math.random() * (currentSection.outerRadius-currentSection.innerRadius);            
            
            if (this.params.imageSizeFewerThreshold > 0 && this.imagesData.list.length < this.params.imageSizeFewerThreshold)
                randomRadius = randomRadius * .1;

            // var randomRadius = Math.random() * (this.params.imagesCircleOuterRadius-this.params.imagesCircleInnerRadius);
            
            var ci = new CarouselImage();
            ci.carouselId = ci.mesh.carouselId = i; // save the id in the mesh (useful when raycasting mesh)
            ci.color = this.imagesData.list[i].color;
            ci.box.scale.x *= this.imagesScale;
            ci.box.scale.y *= this.imagesScale;
            ci.box.scale.z *= this.imagesScale;
            ci.thumbnailPath = this.params.imagesThumbsPath+this.imagesData.list[i].thumb;
            ci.zoomPath = this.params.imagesFullPath+this.imagesData.list[i].full;
            ci.description = this.imagesData.list[i].shortDesc;
            ci.htmlDescription = this.imagesData.list[i].longDesc;            
            if (ci.htmlDescription != "" && this.imagesData.list[i].url_campagna_internal != "" ) {
                ci.htmlDescription = '<a href="'+this.imagesData.list[i].url_campagna_internal+'">'+ci.htmlDescription+'</a>'
            }            
            // add this mesh to the pointer raycast checklist
            this.pointer.meshesToCheck.push(ci.mesh);

            // start loading the thumbnail texture
            ci.loadThumbnail();
            // ci.on('thumbnail_loaded', () =>
            // {
            //     // this.imagesData.list[ci.carouselId].carouselImage.setSizeRatio();
            // });

            // calculate final position of the images
            ci.box.position.x = -(currentSection.innerRadius+randomRadius) * Math.cos(angle*(i+1));
            ci.box.position.y = (currentSection.innerRadius+randomRadius) * Math.sin(angle*(i+1));
            // z position random
            var zRange = this.params.imagesFloorEndDistance-this.params.imagesFloorStartDistance;
            var zFinalPosition = this.params.imagesFloorStartDistance + (Math.random() * (i+1))/(i+1)*zRange;
            ci.box.position.z = this.params.imageOutZStart;
           
            // counter rotation (keep the gsap timeline in an array, to speed it up when needed)
            var counterRotation = gsap.timeline();
            counterRotation.to(ci.box.rotation,{
                duration:this.params.imagesCircleRotationDuration,
                z: (-this.params.imagesCircleRotationDirection)*2* Math.PI,
                ease:"none",
                repeat:-1
            });
            this.groupRotationCounterAnimations.push(counterRotation)

            // shuffle main animation
            this.mainAnimation.from(ci.box.position, {
                duration: this.params.imageOutDuration,
                ease: this.params.imageOutEase,
                x: 0,
                y: 0
                },
                this.params.imageOutDelay*(i+1));
            this.mainAnimation.from(ci.box.scale, {
                duration: this.params.imageOutDuration,
                ease: this.params.imageOutEase,
                x: this.params.imageOutScaleStart,
                y: this.params.imageOutScaleStart,
                z: this.params.imageOutScaleStart
                },
                this.params.imageOutDelay*(i+1));
            this.mainAnimation.to(ci.box.position, {
                duration: this.params.imageOutDuration,
                ease: this.params.imageOutEase,
                z: zFinalPosition
                },
                "<"+this.params.imageOutDuration/2);

            // shuffle back animation
            this.reverseAnimation.to(ci.box.position, {
                duration: this.params.imageInDuration,
                ease: this.params.imageInEase,
                z: this.params.imageOutZStart
                },
                this.params.imageInDelay*(i+1));
            this.reverseAnimation.to(ci.box.scale, {
                duration: this.params.imageInDuration,
                ease: this.params.imageInEase,
                x: this.params.imageOutScaleStart,
                y: this.params.imageOutScaleStart,
                z: this.params.imageOutScaleStart
                },
                "<"+this.params.imageInDuration/2);
            this.reverseAnimation.to(ci.box.position, {
                duration: this.params.imageInDuration,
                ease: this.params.imageInEase,
                x: 0,
                y: 0
                },
                "<");

            // show colors while loading
            ci.changeToColor();

            // add the image to the list and to the group
            this.imagesData.list[i].carouselImage = ci;
            this.imageGroup.add(this.imagesData.list[i].carouselImage.box);
        }
        
        // add camera movements
        this.mainAnimation.add(()=>{
            this.app.camera.moveAnimation(false);
         },this.params.camera.movingDuration+this.params.camera.movingBackOffset); // move the camera back

        // start main animation and keep the reverse waiting
        this.mainAnimation.restart();
        this.reverseAnimation.pause();
    }

    /**
     * if there's a zoomed image, resets all to gallery
     */
    resetZoomedImage() {
        // console.log("reset")
        this.zoomedItemId = null;
        
        this.fadeOtherImages(true);
        this.toggleLongDescription(false);
        this.toggleShortDescription(true);
        this.status = this.STATES.THUMBNAILS;
        this.fadePlane.visible = false;
        this.fadePlane.material.color=this.params.fullImage.fadePlaneColor;

    }

    /**
     * when something is wrong with data, go to home
     */
    onDataError() {
        window.location.href="";
    }

    /**
     * checks if all thumbnails are ready
     */
    allThumbnailsLoaded() {
        if (this.loadedThumbnails.length == this.imagesData.list.length)
            return true;
        return false;
    }

    /**
     * run though images and switch all to thumbnails, if needed
     */
    showThumbnails() {
        var tl = gsap.timeline({onComplete:() => {
            this.status = this.STATES.THUMBNAILS;
        }});
        this.loadedThumbnails.forEach((ci, i) => {
            switch (ci.viewState) {
                case ci.VIEWSTATES.COLOR : 
                case ci.VIEWSTATES.ZOOM : {
                    if (!ci.status == ci.STATES.THUMBNAIL_LOADING) {
                        tl.add(()=>{
                            ci.changeToThumbnail();
                        },"<"+this.params.thumbnailChangeDelay); 
                    }                        
                }
            }
        });
        
    }

    /**
     * run though images and switch all to color, if needed
     */
    showColor() {
        var tl = gsap.timeline();
        this.imagesData.list.forEach((cid, i) => {
            var ci = cid.carouselImage;
            switch (ci.viewState) {
                case ci.VIEWSTATES.THUMBNAIL : 
                case ci.VIEWSTATES.ZOOM : {
                    tl.add(()=>{
                        ci.changeToColor();
                    },"<"+this.params.thumbnailChangeDelay); 
                    
                }
            }
        });

    }

    /**
     * Check if the requested image ID is valid
     */
    checkRequestId() {
        this.lastCarouselId = this.carouselId;
        this.carouselId = null
        for (var i=0; i < this.imagesData.list.length; i++) {
            var ci = this.imagesData.list[i].carouselImage;
            // check if requested ID is among these
            if (url.get('id') != null && ci.carouselId == url.get('id')) {
                // found
                this.carouselId = ci.carouselId;
                this.pointer.activeMesh = ci.mesh;
                this.pointer.activeMesh.carouselId = this.carouselId;
                return;
            }
        }
    }

    /**
     * helper function that sends an image back to carousel
     */
    animateBackToCarousel(animation, ci) {
            // animation.to(ci.box.position, { // send towards camera
            //     duration: this.params.fullImage.animationOutLength,
            //     ease: this.params.fullImage.animationOutEase,
            //     x: this.params.camera.position.x,
            //     y: this.params.camera.position.y,
            //     z: this.params.camera.position.z*2,
            // });
            animation.to(ci.box.scale, { // make disappear
                duration: 0,
                x: 0,
                y: 0,
                z: 0,
            });
            animation.to(ci.box.position, { // bring behind the floor
                duration: 0,
                x: this.lastItemPosition.x,
                y: this.lastItemPosition.y,
                z: -this.lastItemPosition.z,
            });
            animation.to(ci.box.position, { // bring back to initial position
                duration: this.params.fullImage.animationOutLength,
                ease: this.params.fullImage.animationOutEase,
                x: this.lastItemPosition.x,
                y: this.lastItemPosition.y,
                z: this.lastItemPosition.z,
            });
            animation.to(ci.box.scale, { // reset scale
                duration: this.params.fullImage.animationOutLength,
                ease: this.params.fullImage.animationOutEase,
                x: this.imagesScale,
                y: this.imagesScale,
                z: this.imagesScale
            },"<");

            animation.play()
    }

    /**
     * helper function that sends the image from the carousel to the zoom place 
     */
    animateToZoom(animation,ci) {
        this.lastItemPosition = Object.assign({},ci.box.position);

        this.zoomScaleRatio = this.params.fullImage.scaleRatio / this.imagesScale;
        if (ci.sizeRatio < 1) {
            // horizontal image: correct
            var winRatio = this.app.sizes.height / this.app.sizes.width;
            this.zoomScaleRatio /= winRatio;
        }
        //this.zoomScaleRatio = parseInt(ci.box.scale.x) * parseInt(this.zoomScaleRatio) ;

        animation.to(ci.box.position, { // move in front of the camera
            duration: this.params.fullImage.animationInLength,
            ease: this.params.fullImage.animationInEase,
            x: this.params.fullImage.position.x,
            y: this.params.fullImage.position.y,
            z: this.params.fullImage.position.z
        });
        animation.to(ci.box.scale, {    // make image bigger
            duration: this.params.fullImage.animationInLength,
            ease: this.params.fullImage.animationInEase,
            x: this.zoomScaleRatio,
            y: this.zoomScaleRatio,
            z: this.zoomScaleRatio
        },"<");
        animation.add(()=>{
            if (ci.color) {
                this.fadePlane.material.color = new THREE.Color(ci.color).add(new THREE.Color(this.params.fullImage.fadePlaneAddColor));
            }
                
            this.fadePlane.visible = true;  
        });
        
        animation.play()
    }

    /**
     * handle a zoom image request
     */
    zoomRequestHandler () {
                    
        if (!this.imagesData.loaded || this.lastGalleryType != this.imagesData.galleryType || this.lastGalleryId != this.imagesData.galleryId)
            return;  // still no images loaded, or we have just changed all the images, so we don't have to zoom
            
        this.checkRequestId();
        var loadTheImage = false;

        if (this.status == this.STATES.ZOOM) { // we already have a zoomed image, we close it or we change it
            
            // console.log("zoom mode with image ",this.zoomedItemId," and requested ",this.carouselId)

            if (this.carouselId == null || this.carouselId == this.zoomedItemId) { // we have to go back to thumbnails

                // console.log("go to thumbnails")

                var ci = this.imagesData.list[this.zoomedItemId].carouselImage;
                if (ci.status == ci.STATES.ZOOM_LOADING)
                    return; // no action if loading in progress
                this.active = this.pointer.active = false; // temporary disable interaction 
                ci.status = ci.STATES.ANIMATING
                var animation = gsap.timeline({ // prepare distortion animation
                    paused: true,
                    onComplete: () => {
                        // ci.displaceAnimation()
                        ci.status = ci.STATES.READY
                        this.active = this.pointer.active = true;
                    }
                });                
                
                ci.changeToThumbnail();
                this.resetZoomedImage()

                url.apply({
                    gt: this.imagesData.galleryType,
                    gid: this.imagesData.galleryId,
                });

                // send back to carousel
                this.animateBackToCarousel(animation,ci);

            } else if (this.carouselId != this.zoomedItemId) { // we have to change the image
                
                // console.log("change to ",this.carouselId," from ", this.zoomedItemId)
                var oldCi = this.imagesData.list[this.zoomedItemId].carouselImage;
                var awayAnimation = gsap.timeline({
                    paused: true,
                    onComplete: () => {
                        this.active = this.pointer.active = true;
                    }});
                this.active = this.pointer.active = false; // temporary disable interaction
                
                var isNext = this.carouselId < this.zoomedItemId; //relative to carousel sequence. the final position is different
                
                awayAnimation.to(oldCi.box.position, { // send away image
                    duration: isNext ? this.params.nextImage.animationLength: this.params.prevImage.animationLength,
                    ease: isNext ? this.params.nextImage.animationEase: this.params.prevImage.animationEase,
                    x: isNext ? this.params.nextImage.position.x: this.params.prevImage.position.x,
                    y: isNext ? this.params.nextImage.position.y: this.params.prevImage.position.y,
                    z: isNext ? this.params.nextImage.position.z: this.params.prevImage.position.z,
                });
                // send old image back to carousel
                this.animateBackToCarousel(awayAnimation,oldCi);

                this.zoomedItemId = this.carouselId;

                loadTheImage = true;

            } 

        } 
        
        if ((this.status == this.STATES.THUMBNAILS || loadTheImage) && this.carouselId != null) { // we have thumbnails (or we changed image), we load the zoomed image
            
            // console.log("go to image ",this.carouselId)            
            var ci = this.imagesData.list[this.carouselId].carouselImage;
            if (ci.status == ci.STATES.ZOOM_LOADING)
                return; // no action if loading in progress
            this.active = this.pointer.active = false; // temporary disable interaction 

            var animation = gsap.timeline({ // prepare distortion animation
                paused: true,
                onComplete: () => {
                    ci.displaceAnimation()
                    ci.status = ci.STATES.READY
                    this.active = this.pointer.active = true;
                }
            });
            ci.status = ci.STATES.ANIMATING

            this.zoomedItemId = ci.carouselId;
            
            ci.on('zoom_ready', () => // will be executed when zoomed texture is loaded
            {
                this.animateToZoom(animation,ci);
            }); 
            ci.changeToZoom(); // starts loading the zoom texture
            this.fadeOtherImages(false);            
            this.longDescriptionContainer.innerHTML = ci.htmlDescription;
            if (this.longDescriptionContainer.innerHTML) {
                this.toggleLongDescription(true);
            } else
                this.toggleLongDescription(false);
            this.toggleShortDescription(false);
            this.status = this.STATES.ZOOM;

        }

    }

    /**
     * When URL changes, decide what to do
     */
    onUrlChange()
    {
        if (!this)
            return; // at first call there's no object yet

        if (url.get('gt') == null || ((url.get('gt') != null && url.get('gid') == null))) {
            // no gallery type: go to home
            // or: gallery type but no gallery id: go to home
            url.apply({
                gt: "home",
                gid: null,
                id: url.get('id') 
            });
            return;
        }

        // store old values, set new ones
        this.lastGalleryType = this.imagesData.galleryType;
        this.lastGalleryId = this.imagesData.galleryId;
        this.imagesData.galleryType = url.get('gt');
        this.imagesData.galleryId = url.get('gid');

        if (!this.imagesData.loaded || this.lastGalleryType != this.imagesData.galleryType || this.lastGalleryId != this.imagesData.galleryId) {
            // images still not loaded, or gallery changed:
            // load new images
            this.showColor(); // change to color while waiting
            this.reverseAnimation.play();
        } else {
            // same gallery: continue to zoom image checks
            this.zoomRequestHandler()
        }
    }

    /**
     * when user clicks
     */
    click () {
        if (!this.active || !this.pointer.active)
            return false; // input disabled
        
        if (this.zoomedItemId == null) {
            // no zoomed image: find a clicked thumbnail
            if (this.pointer.activeMesh && this.pointer.activeMesh.carouselId >= 0) {
                // user clicked on a mesh
                url.apply({
                    gt: this.imagesData.galleryType,
                    gid: this.imagesData.galleryId,
                    id:this.pointer.activeMesh.carouselId
                });
            } else {
                // user clicked elsewhere, go back to thumbnails
                url.apply({
                    gt: this.imagesData.galleryType,
                    gid: this.imagesData.galleryId
                });
            }        
        } else {
            // zoomed image, go back to thumbnails
            url.apply({
                gt: this.imagesData.galleryType,
                gid: this.imagesData.galleryId
            });
        }
    }

    /**
     * rotates other images in inactive position
     * when back=TRUE goes back to original state
     * when back=FALSE goes to modified state
     */
    fadeOtherImages(back) {
        var rotation = 0;
        var worldRotation = 0;

        if (!back) {
            rotation = this.params.imageDeactivateRotationAmount;
            worldRotation = this.params.imageDeactivateWorldInclineAmount;
        }  

        // world incline
        if (this.params.imageDeactivateWorldInclineAmount != 0) {
            this.inclineOnZoom.forEach((item) => {
                gsap.to(item.rotation, {
                    duration: this.params.imageDeactivateWorldInclineDuration,
                    ease: this.params.imageDeactivateWorldInclineEase,
                    x: worldRotation
                });    
            });    
        }

        // flip each image;
        if (this.params.imageDeactivateRotationAmount != 0) {
            this.imagesData.list.forEach((item) => {
                if (item.carouselImage.carouselId != this.pointer.activeMesh.carouselId) {
                    gsap.to(item.carouselImage.mesh.rotation, {
                        duration: this.params.imageDeactivateRotationDuration,
                        ease: this.params.imageDeactivateRotationEase,
                        x: rotation
                    });
                }
            });            
        }
    }

    /**
     * activate/deactivate long description
     */
    toggleLongDescription(on) {
        if (on) {
            //l'ascoltatore è su UI
            this.longDescriptionContainer.dispatchEvent(new Event('longdescription_on'));
            
            // make description visible
            this.active = false; // stop user interaction
            if( this.params.beforeLongDescriptionAppears &&
                typeof(this.params.beforeLongDescriptionAppears) === "function")                
                this.params.beforeLongDescriptionAppears(this.longDescriptionContainer);                
            setTimeout(()=>{
                this.longDescriptionContainer.style.display = "block"
                if( this.params.afterLongDescriptionAppears &&
                    typeof(this.params.afterLongDescriptionAppears) === "function")
                    this.params.afterLongDescriptionAppears(this.longDescriptionContainer).then(() => {
                        this.active = true; // resume user interaction
                    })
            }, (this.params.longDescriptionAppearDelay ?? 0)*1000);                                
        } else {
            // make description invisible
            this.active = false; // stop user interaction
            if( this.params.beforeLongDescriptionDisappears &&
                typeof(this.params.beforeLongDescriptionDisappears) === "function")                
                this.params.beforeLongDescriptionDisappears(this.longDescriptionContainer);
            setTimeout(()=>{
                this.longDescriptionContainer.innerHTML = null;
                this.longDescriptionContainer.style.display = "none";
                if( this.params.afterLongDescriptionDisappears &&
                    typeof(this.params.afterLongDescriptionDisappears) === "function")
                    this.params.afterLongDescriptionDisappears(this.longDescriptionContainer); 
                    this.active = true; // resume user interaction
            }, (this.params.longDescriptionDisappearDelay ?? 0)*1000);                                 
        }
    }

    /**
     * activate/deactivate long description
     */
    toggleShortDescription(on) {
        if (on) {
            this.shortDescriptionContainer.style.display = "none" //sempre disattivata: metti qui block per attivarla
        } else {
            this.shortDescriptionContainer.style.display = "none"
        }
    }

    /**
     * loads next image
     */
    goToPrevImage() {
        if (this.zoomedItemId != null &&
            this.imagesData.list[this.zoomedItemId] != null) {
            // we have a zoomed image, load previous
            var ni = this.zoomedItemId-1
            if (ni < 0)
                ni = this.imagesData.list.length 
            url.apply({
                gt: this.imagesData.galleryType,
                gid: this.imagesData.galleryId,
                id:ni
            });
        }    
    }
    goToNextImage() {
        if (this.zoomedItemId != null &&
            this.imagesData.list[this.zoomedItemId] != null) {
            // we have a zoomed image, load next
            var ni = this.zoomedItemId+1
            if (ni > this.imagesData.list.length)
                ni = 0                
            url.apply({
                gt: this.imagesData.galleryType,
                gid: this.imagesData.galleryId,
                id:ni
            });
        }    
    }

    /**
     * when user moves:
     * dragging rotates carousel
     * mouse over image activates short description
     */
    move () {
        if (!this.active)
            return;

        // dragging rotates carousel
        if (this.dragging && this.zoomedItemId == null) {

            if (this.draggingDirection == null) {
                // angle of mouse rotation
                var angle = Math.atan2(this.pointer.mouse2D.y - this.draggingStart.y, this.pointer.mouse2D.x - this.draggingStart.x);
                // correct direction when x < 0 and keep consistent with previous direction
                var correctedAngle = this.params.imagesCircleRotationDirection * Math.sign(this.pointer.mouse2D.x) * angle;
                this.draggingDirection = Math.sign(correctedAngle)
            }
            var deltaProgress = Math.min(this.params.imagesCircleRotationMaxSpeed, this.draggingDirection * this.pointer.dragDistance * this.params.imagesCircleRotationSpeed);
            this.groupRotationAnimation.totalTime(this.groupRotationAnimation.totalTime()+deltaProgress)
            this.groupRotationCounterAnimations.forEach((item) => {
                item.totalTime(item.totalTime()+deltaProgress);
            });
        }

        // update short description
        if (this.pointer.activeMesh && this.pointer.activeMesh.carouselId >= 0 && this.zoomedItemId == null) {
            var cil = this.imagesData.list[this.pointer.activeMesh.carouselId];
            if (cil === undefined)
                return;
            var ci = cil.carouselImage;
            this.shortDescriptionContainer.innerHTML = ci.description; 
        } else {
            this.shortDescriptionContainer.innerHTML = null;
        }
    }

    /**
     * Start and stop dragging
     */
    down() {
        if (!this.active)
            return;
        
        this.dragging = this.pointer.dragging = true;
        this.draggingStart = this.pointer.mouse2D.clone();
        this.draggingDirection = null;
        this.pointer.dragDistance = 0; // bug on mobile with distance not reset?
    }
    up() {
        this.dragging = this.pointer.dragging = false;
        this.draggingDirection = null;
        this.pointer.dragDistance = 0; // bug on mobile with distance not reset?

        if (this.status == this.STATES.ZOOM) {            
            if (this.zoomedItemId != null && this.imagesData.list[this.zoomedItemId] != undefined) {
                var ci = this.imagesData.list[this.zoomedItemId].carouselImage;
                ci.box.scale.x = ci.box.scale.y = ci.box.scale.z = this.zoomScaleRatio;
            }
        }
        
        if (!this.active)
            return;        

        this.groupRotationAnimation.timeScale(1);
        this.groupRotationCounterAnimations.forEach((item) => {
            item.timeScale(1);
        });
    }

    /**
     * handle pinch to zoom
     */
    pinch_change() {
        if (this.status == this.STATES.ZOOM) {            
            if (this.zoomedItemId != null && this.imagesData.list[this.zoomedItemId] != undefined) {
                var ci = this.imagesData.list[this.zoomedItemId].carouselImage;
                var winDiagonal = Math.sqrt(this.app.sizes.height*this.app.sizes.height + this.app.sizes.width*this.app.sizes.width);
                var newScaleRatio = Math.max(this.zoomScaleRatio + (this.pointer.pinchDistanceEnd - this.pointer.pinchDistanceStart) * this.params.fullImage.scaleRatioPinchFactor / winDiagonal,1);                
                ci.box.scale.x = ci.box.scale.y = ci.box.scale.z = newScaleRatio;
                this.zoomScaleRatio = newScaleRatio;
            }
        }
    }

    /**
     * main update:
     * checks wheter or not show short description
     * calls update on each carousel image
     */
    update(){
        if (this.shortDescriptionContainer.innerHTML != "")
            this.toggleShortDescription(true);
        else
            this.toggleShortDescription(false);

        this.imagesData.list.forEach((item) => {
            if (item.carouselImage)
                item.carouselImage.update()
        });
    }

    destroy() {
        this.disposeOfChildren(this.imageGroup);
        this.imagesData.off('loaded')
        this.pointer.off('click')
        this.pointer.off('move')
        this.pointer.off('up')
        this.pointer.off('down')
        this.imagesData.list.forEach((item) => {
           item.carouselImage.off('zoom_ready'); 
           item.carouselImage.off('thumbnail_loaded');
        });
    }

    /**
     * Recursevely removes children of the THREE Object passed
     */
    disposeOfChildren(obj) {
        for (var i = 0; i < obj.children.length; i++) {
            var child = obj.children[i];
            if(child instanceof THREE.Mesh) {
                child.geometry.dispose()
                // Loop through the material properties
                for(const key in child.material) {
                    const value = child.material[key]
                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                        value.dispose()
                }
            } else if(child instanceof THREE.Group) {
                this.disposeOfChildren(child);
            }   
            child.removeFromParent();     
        }
        obj.clear();
    }
}
