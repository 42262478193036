import * as dat from 'lil-gui'

let instanceDebug = null

export default class Debug
{
    constructor(_active)
    {
        // Singleton
        if(instanceDebug)
            return instanceDebug;
        instanceDebug = this;

        this.active = _active ?? true;
        this.folders = {}

        if(this.active)
        {
            this.ui = new dat.GUI()
        }
    }

    dispose() {
        if (this.active)
            this.ui.destroy()
    }

    addFolder(str) {
        if(this.active) {
            this.folders[str] = this.ui.addFolder(str);
        }
    }
}