import * as THREE from 'three'
import Application from '../Application.js'
import url from 'url-parameters';

export default class Floor
{
    constructor()
    {
        this.app = new Application()
        this.params = this.app.configuration.parameters;
        this.scene = this.app.scene
        this.resources = this.app.resources
        this.color = this.params.floorDefaultColor;
        this.lastColor = this.params.floorDefaultColor;
        this.colors = Array(this.params.colorDial.colors.length);

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry()
    {
        this.geometry = new THREE.CircleGeometry(this.params.floorSize, 64)
    }

    setMaterial()
    {
        this.textures = {}

        if (this.params.worldIsFlat) {
            this.material = new THREE.MeshBasicMaterial({
                color: this.color
            });
        } else {
            this.material = new THREE.MeshStandardMaterial({
                color: this.color
            });
        }

        if (this.resources.items.floorColorTexture) {
            this.textures.color = this.resources.items.floorColorTexture
            this.textures.color.encoding = THREE.sRGBEncoding
            this.textures.color.repeat.set(this.params.floorTiling, this.params.floorTiling)
            this.textures.color.wrapS = THREE.RepeatWrapping
            this.textures.color.wrapT = THREE.RepeatWrapping

            this.material.map = this.textures.color
        }
        
        if (!this.params.worldIsFlat) {
            if (this.resources.items.floorNormalTexture) {
                this.textures.normal = this.resources.items.floorNormalTexture
                this.textures.normal.repeat.set(this.params.floorTiling, this.params.floorTiling)
                this.textures.normal.wrapS = THREE.RepeatWrapping
                this.textures.normal.wrapT = THREE.RepeatWrapping
    
                this.material.normalMap = this.textures.normal;
                this.material.normalScale = new THREE.Vector2(this.params.floorNormalScale,this.params.floorNormalScale);
            }
    
            if (this.resources.items.floorRoughnessTexture) {
                this.textures.roughness = this.resources.items.floorRoughnessTexture
                this.textures.roughness.repeat.set(this.params.floorTiling, this.params.floorTiling)
                this.textures.roughness.wrapS = THREE.RepeatWrapping
                this.textures.roughness.wrapT = THREE.RepeatWrapping
    
                this.material.roughnessMap = this.textures.roughness;
            }
    
        }

    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.receiveShadow = this.params.imageCastShadow;
        this.mesh.castShadow = false;
        this.scene.add(this.mesh)
    }

    update()
    {
        if (url.get('gt') == "colore") {
            var colName = url.get('gid');
            if (this.colors[colName] === undefined) {
                // create color list the first time
                this.params.colorDial.colors.forEach((cols, i) => {
                    if (cols[1] == "colore")
                        this.colors[cols[2]] = cols[0]
                });
            }
            if (this.colors[colName] != this.lastColor) {
                this.changeColor(this.colors[colName]);
                // change the color shutter color too
                if (this.app.colorDial)
                    this.app.colorDial.changeColor(this.colors[colName])
            }
        } else {
            if (this.color != this.params.floorDefaultColor) {
                this.changeColor(null);
                // change the color shutter color too
                if (this.app.colorDial)
                    this.app.colorDial.changeColor(null)
            }
        }        
    }

    /**
     * change to a specific color, adding the variation. If provided with null color, the default color will be set
     */
    changeColor(color)
    {
        this.lastColor = this.color;
        if (color == null) {
            this.color = this.params.floorDefaultColor;
            this.material.color = new THREE.Color(this.params.floorDefaultColor);
        }            
        else {
            this.color = color;
            this.material.color = new THREE.Color(this.color).add(new THREE.Color(this.params.floorGalleryAddColor));
        }
        this.material.needsUpdate = true;
    }
}