export default [
    /*
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name: 'floorColorTexture',
        type: 'texture',
        path: 'textures/floor_color.png'
    },
    {
        name: 'floorNormalTexture',
        type: 'texture',
        path: 'textures/floor_normal.png'
    },
    {
        name: 'floorRoughnessTexture',
        type: 'texture',
        path: 'textures/floor_roughness.png'
    },
    {
        name: 'displaceTexture',
        type: 'texture',
        path: 'textures/displace.png'
    },
    {
        name: 'colorDialOpacity',
        type: 'texture',
        path: 'textures/colorDialOpacity.png'
    },
    */
    {
        name: 'colorShutterOpacity',
        type: 'texture',
        path: 'textures/colorShutterOpacity.png'
    },
    {
        name: 'colorShutterShadow',
        type: 'texture',
        path: 'textures/colorShutterShadow.png'
    },
    {
        name: 'fadePlaneOpacity',
        type: 'texture',
        path: 'textures/fadePlaneOpacity.png'
    },
    {
        name: 'logo',
        type: 'texture',
        path: 'textures/logo.png'
    },
    {
        name: 'logoborder',
        type: 'texture',
        path: 'textures/logoborder.png'
    },
    {
        name: 'loader',
        type: 'texture',
        path: 'textures/loader.png'
    },
    {
        name: 'photomask',
        type: 'texture',
        path: 'textures/photomask.png'
    },
    {
        name: 'introimage',
        type: 'texture',
        path: window.innerWidth > 767 ? 'textures/intro.jpg' : 'textures/intro-small.jpg'
    },
    {
        name: 'introOpacity',
        type: 'texture',
        path: 'textures/introOpacity.jpg'
    }
]