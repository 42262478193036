import * as THREE from 'three'
import Application from '../Application.js'
import EventEmitter from '../Utils/EventEmitter.js'
import { gsap } from "gsap";
import { TimelineMax } from 'gsap/gsap-core.js';

export default class ColorShutter extends EventEmitter
{
    constructor(opened = true)
    {
        super();

        this.app = new Application()
        this.params = this.app.configuration.parameters;
        this.scene = this.app.scene
        this.resources = this.app.resources;
        this.opened = opened;
        this.openingClosing = false;
        this.peeping = false;

        // get colors and links from configuration
        this.colors = Array(this.params.colorDial.colors.length);
        this.links = Array(this.params.colorDial.colors.length);
        for (const i in this.params.colorDial.colors) {
            this.colors[i] = this.params.colorDial.colors[i][0];
            this.links[i] = {
                galleryType: this.params.colorDial.colors[i][1],
                galleryId: this.params.colorDial.colors[i][2]
            };
        }

        this.color = this.params.colorShutter.defaultColor;
        this.lastColor = this.params.colorShutter.defaultColor;

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry() {
        // logo
        this.logogeometry = new THREE.CircleGeometry(this.params.logoRadius,48)
        this.logobordergeometry = new THREE.RingGeometry(this.params.logoRadius-this.params.colorShutter.borderWidth/2,this.params.logoRadius+this.params.colorShutter.borderWidth/2,48)
        this.shadowgeometry = new THREE.CircleGeometry(this.params.logoRadius*this.params.colorShutter.fakeShadowScale,8)

        // blades
        this.sectorCount = this.colors.length;
        this.sectorWidth = Math.PI*2 / this.sectorCount; // sector size according to number of colors
        this.sectorInnerWidth = this.sectorWidth*(1-this.params.colorDial.gapPercent);
        
        this.geometries = Array(this.sectorCount);

        for(const i in this.colors) {
            this.geometries[i] = new THREE.RingGeometry( 
                this.params.logoRadius - this.params.colorShutter.bladeMaxDistance,
                this.params.logoRadius,
                32,2,
                0, this.sectorInnerWidth);
        }
    }

    setMaterial() {
        // logo
        this.logomaterial = new THREE.MeshBasicMaterial({
            map: this.resources.items.logo,
            color: this.color,
            transparent: true
        })
        this.logomaterial.map.encoding = THREE.sRGBEncoding;
        this.logobordermaterial = new THREE.MeshBasicMaterial({
            map: this.resources.items.logoborder,
            color: this.color,
            transparent: true
        })
        this.logobordermaterial.map.encoding = THREE.sRGBEncoding;
        this.shadowmaterial = new THREE.MeshBasicMaterial({
            map: this.resources.items.colorShutterShadow,
            transparent: true
        })
        this.shadowmaterial.map.encoding = THREE.sRGBEncoding;

        // if needed, create a texture for opacity
        if (this.params.colorShutter.bladeOpacity > 0 && this.params.colorShutter.bladeOpacity < 1) {            
            // this.opacityTexture = new TextureCreator(
            //     1,1, // size
            //     this.params.colorShutter.bladeOpacity, // r
            //     this.params.colorShutter.bladeOpacity, // g
            //     this.params.colorShutter.bladeOpacity, // b
            //     );
        }

        this.materials = Array(this.sectorCount);

        for(const i in this.colors) {
            this.materials[i] = new THREE.MeshBasicMaterial({
                color: this.colors[i]                
            })
            if (this.resources.items.colorShutterOpacity !== undefined) {
                this.materials[i].alphaMap = this.resources.items.colorShutterOpacity;
                this.materials[i].transparent = true;
            } else if (this.params.colorShutter.bladeOpacity > 0 && this.params.colorShutter.bladeOpacity < 1) {//this.opacityTexture != undefined) {
                this.materials[i].alphaMap = this.opacityTexture.texture;
                this.materials[i].opacity = this.params.colorShutter.bladeOpacity;
                this.materials[i].transparent = true;
            }
        }
    }

    setMesh() {
        this.mesh = new THREE.Group();

        //logo
        this.logo = new THREE.Mesh(this.logogeometry, this.logomaterial)
        this.logo.name = "logo";
        // a transparent basic material does not cast shadow
        this.logo.castShadow = false; //this.params.imageCastShadow;
        this.logo.receiveShadow = false;
        this.mesh.add(this.logo)
        
        this.logobehind = new THREE.Mesh(this.logogeometry, this.logobordermaterial)
        this.logobehind.position.z = this.params.colorShutter.fakeShadowPosition.z+0.001;
        this.logobehind.castShadow = false;
        this.logobehind.receiveShadow = false;
        this.mesh.add(this.logobehind)

        this.logoborder = new THREE.Mesh(this.logobordergeometry, this.logobordermaterial)
        this.logoborder.position.z = this.params.colorShutter.borderDistance;
        this.logoborder.castShadow = false;
        this.logoborder.receiveShadow = false;
        this.mesh.add(this.logoborder)

        this.shadow = new THREE.Mesh(this.shadowgeometry, this.shadowmaterial)
        this.shadow.castShadow = false;
        this.shadow.receiveShadow = false;
        this.shadow.position.x = this.params.colorShutter.fakeShadowPosition.x;
        this.shadow.position.y = this.params.colorShutter.fakeShadowPosition.y;
        this.shadow.position.z = this.params.colorShutter.fakeShadowPosition.z;
        this.mesh.add(this.shadow)


        // blades
        this.meshes = Array(this.sectorCount); 

        for(const i in this.colors) {
            this.meshes[i] = new THREE.Mesh(this.geometries[i], this.materials[i])
            this.meshes[i].name = "colorDial_"+i;
            this.meshes[i].colorDialId = i;
            this.meshes[i].castShadow = false;
            this.meshes[i].receiveShadow = false;
            
            this.meshes[i].rotator = new THREE.Group();
            this.meshes[i].scaler = new THREE.Group();
            this.meshes[i].scaler.add(this.meshes[i]);
            this.meshes[i].rotator.add(this.meshes[i].scaler);
            this.meshes[i].position.x = -this.params.logoRadius
            this.meshes[i].scaler.position.x = this.params.logoRadius
            this.meshes[i].scaler.rotation.z = this.params.colorShutter.bladeZRotation;
            this.meshes[i].rotator.position.z = (this.params.colorShutter.borderDistance*0.1) + i*(this.params.colorShutter.borderDistance/this.colors.length)
            this.meshes[i].rotator.rotation.z = -i * this.sectorWidth;  
    
            this.mesh.add(this.meshes[i].rotator);
        }    
        
        this.mesh.position.z = this.params.colorDial.floorDistance;    

        gsap.to(this.mesh.scale, { // beat animation
            duration: this.params.logoBeatDuration,
            repeatDelay: this.params.logoBeatDuration,
            yoyoEase: this.params.logoBeatEase,
            ease: 'power2.out',
            x: this.params.logoBeatScale,
            y: this.params.logoBeatScale,
            z: this.params.logoBeatScale,
            yoyo:true,
            repeat:-1,
            onStart: () => {
                this.peep()
            }
        });

        this.scene.add(this.mesh)
    }
    
    /**
     * animate and open or close. parameter is animation time.
     * If null, uses configuration time
     */
    close(t) {
        var time = t ?? this.params.colorDial.closeDuration;
        this.openingClosing = true;
        var tl = gsap.timeline({
            ease: this.params.colorDial.closeEase,
            onComplete:()=>{
                this.openingClosing = false;
                this.opened = false;
                this.trigger("closed");
            }           
        });
        tl.to(this.logo.scale, { // close animation
            duration: time,
            x: 1,
            y: 1,
        }); 
        for(const i in this.colors) {
            tl.to(this.meshes[i].scaler.scale, { // close animation
                duration: time,
                x: this.params.colorShutter.bladeClosedScale.x,
                y: this.params.colorShutter.bladeClosedScale.y,
                z: this.params.colorShutter.bladeClosedScale.z
            },"<"); 
        }
    }

    open(t) {
        var time = t ?? this.params.colorDial.closeDuration;
        this.openingClosing = true;
        var tl = gsap.timeline({
            ease: this.params.colorDial.closeEase,
            onComplete:()=>{
                this.openingClosing = false;
                this.opened = true;
                this.trigger("opened");
            }           
        });
        tl.to(this.logo.scale, { 
            duration: time,
            x: this.params.colorShutter.logoScaleOnOpen,
            y: this.params.colorShutter.logoScaleOnOpen,
        }); 
        for(const i in this.colors) {
            tl.to(this.meshes[i].scaler.scale, { 
                duration: time,
                x: this.params.colorShutter.shutterActiveScaleX,
                y: this.params.colorShutter.shutterActiveScaleY,
                z: this.params.colorShutter.shutterActiveScaleZ
            },"<"); 
        }
    }

    /**
     * show half of the wheel for a moment
     */
    peep() {    
        if (!this.opened && !this.openingClosing) {
            this.peeping = true;
            this.peepingAnimation = gsap.timeline({
                delay: 1.7,
                onComplete:()=>{ this.peeping = false; },
            });
            for(const i in this.colors) {
                this.peepingAnimation.to(this.meshes[i].scaler.scale, { // half open animation
                    duration: this.params.colorDial.peepDuration/2,
                    ease: this.params.colorDial.peepEase,
                    x: this.params.colorShutter.bladePeepScale.x,
                    y: this.params.colorShutter.bladePeepScale.y,
                    z: this.params.colorShutter.bladePeepScale.z
                },0);
                this.peepingAnimation.to(this.meshes[i].scaler.scale, { // half open animation
                    duration: this.params.colorDial.peepDuration/2,
                    ease: this.params.colorDial.peepEase,
                    x: this.params.colorShutter.bladeClosedScale.x,
                    y: this.params.colorShutter.bladeClosedScale.y,
                    z: this.params.colorShutter.bladeClosedScale.z  
                },"<"+this.params.colorDial.peepDuration/2);
            }
        }
    }

    /**
     * open and close
     */
    toggle() {
        if (this.openingClosing)
            return;
        
        if (this.peeping) {
            // reset the peeping animation
            // console.log(this.peepingAnimation)
            this.peepingAnimation.totalProgress(1).kill();
        }

        if (this.opened)
            this.close();
        else
            this.open();
    }

    /**
     * set a special color (used in color galleries to shade the logo)
     */
    changeColor(color)
    {
        this.lastColor = this.color;
        
        if (color == null) {
            this.color = this.params.colorShutter.defaultColor;
            this.logomaterial.color = new THREE.Color(this.params.colorShutter.defaultColor);
            this.logobordermaterial.color = new THREE.Color(this.params.colorShutter.defaultColor);
        } else {
            this.color = color;
            this.logomaterial.color = new THREE.Color(this.color).add(new THREE.Color(this.params.colorShutter.galleryAddColor));
            this.logobordermaterial.color = new THREE.Color(this.color).add(new THREE.Color(this.params.colorShutter.galleryAddColor));
        }
        this.logomaterial.needsUpdate = true;
    }

}