import * as THREE from 'three'
import Application from './Application.js'

export default class Renderer
{
    constructor(_app)
    {
       
        this.app = _app ?? new Application() // creates a new istance, but it's a singleton, so the already existing istance is provided
        this.canvas = this.app.canvas
        this.sizes = this.app.sizes
        this.scene = this.app.scene
        this.camera = this.app.camera
        this.params = this.app.configuration.parameters;

        this.setInstance()
    }

    setInstance()
    {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        })
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.NoToneMapping
        this.instance.toneMappingExposure = this.params.toneMappingExposure
        if (!this.params.worldIsFlat) {
            this.instance.physicallyCorrectLights = true
            this.instance.shadowMap.enabled = true
            this.instance.shadowMap.type = THREE.PCFShadowMap;    
        }
        this.instance.setClearColor(this.params.clearColor)
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    }

    update()
    {
        this.instance.render(this.scene, this.camera.instance)
    }
}