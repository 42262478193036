import * as THREE from 'three'
import Application from './Application.js'
import EventEmitter from './Utils/EventEmitter.js'
import { gsap } from "gsap";

import fullImageDistortionFragmentShader_Main from './Shaders/fullImage_distortion_fragment_main.glsl'
import fullImageDistortionDeclarations from './Shaders/fullImage_distortion_declarations.glsl'


export const CarouselImage_VIEWSTATES =  { // constants for view states
    COLOR:0,
    THUMBNAIL: 1,
    ZOOM: 2
};
export const CarouselImage_STATES =  { // constants for loading states
    THUMBNAIL_LOADING:0,
    ZOOM_LOADING:0,
    ANIMATING:1,
    READY: 2
};
/**
 * Single image of the carousel
 */
export default class CarouselImage extends EventEmitter
{
    constructor(prefetch_zoom = false)
    {
        super();
        
        this.VIEWSTATES = CarouselImage_VIEWSTATES;
        this.STATES = CarouselImage_STATES;

        this.app = new Application()
        this.params = this.app.configuration.parameters;
        this.scene = this.app.scene
        this.resources = this.app.resources;
        this.textureLoader = new THREE.TextureLoader();

        this.prefetch_zoom = prefetch_zoom;  // if true, immediately starts loading the zoom texture
        this.color = this.rgbToHex(0,0,0);
        this.colorMaterial = null;
        this.viewState = this.VIEWSTATES.COLOR;
        this.status = this.STATES.READY;
        this.sizeRatio = 1;
        this.thumbnailPath = null;
        this.thumbnailTexture = null;  
        this.thumbnailMaterial == null      
        this.zoomPath = null;
        this.zoomTexture = null;        
        this.zoomMaterial == null      
        this.maskTexture = null;  

        this.carouselId = null;
        this.description = null;
        this.htmlDescription = null;
        this.carouselId = null;

        this.box = new THREE.Group(); // box to transform the whole image
        if (!this.params.fullImage.distortionDisable) {
            this.zoomShaderUniforms = { // this will be updated to animate the shader
                time: {value:0},
                progress: {value:0},
                displacemap: {value:this.resources.items.displaceTexture}
            }     
        }

        this.pointer = this.app.carousel.pointer;   // pointer
        this.pointer.on('move', () =>  { this.onMouseMove()  });
        this.mouseOver = false;

        this.setGeometry()
        this.setMaterial()
        this.loadThumbnail()
        if (this.prefetch_zoom)
            this.loadZoom()
        this.setMesh()

        this.createLoadingPlaceholder()
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(this.params.imageSquareSize,this.params.imageSquareSize);
    }

    /**
     * first settings of material
     */
    setMaterial() {
        if (this.params.imageUseMask) {
            this.colorMaterial = new THREE.MeshBasicMaterial({
                color: this.color,
                alphaMap: this.resources.items.photomask,
                transparent: true
            });
        } else {
            this.colorMaterial = new THREE.MeshBasicMaterial({
                color: this.color,
                //side: THREE.DoubleSide
            });    
        }
        this.colorMaterial.needsUpdate = true;
    }
    
    /**
     * first creation of mesh
     */
    setMesh()  {
        this.mesh = new THREE.Mesh(this.geometry, this.colorMaterial)

        this.mesh.position.x = 0;
        this.mesh.position.y = 0;
        this.mesh.position.z = 0;
        this.mesh.castShadow = this.params.imageCastShadow;
        this.mesh.receiveShadow = false;

        this.changeToColor(); // at first run: color

        this.box.add(this.mesh)
        this.scene.add(this.box)
    }

    /**
     * creates the geometry for the loading placeholder
     */
    createLoadingPlaceholder() {
        this.loadingPlaceholderMesh = new THREE.Mesh(
            new THREE.PlaneGeometry(this.params.imageSquareSize/2,this.params.imageSquareSize/2), 
            new THREE.MeshBasicMaterial({
                map: this.resources.items.loader,
                transparent: true
            })
        );
        this.loadingPlaceholderMesh.castShadow = false
        this.loadingPlaceholderMesh.receiveShadow = false
        this.loadingPlaceholderMesh.material.needsUpdate = true;

        this.loadingPlaceholderMesh.position.x = 0;
        this.loadingPlaceholderMesh.position.y = 0;
        this.loadingPlaceholderMesh.position.z = this.params.loaderDistance;

        gsap.to(this.loadingPlaceholderMesh.rotation, {
            duration: this.params.loaderRotationDuration,
            z: 2* Math.PI,
            ease:"none",
            repeat:-1        
        });      
        this.loadingPlaceholderMesh.visible = false;

        this.box.add(this.loadingPlaceholderMesh)
    }

    /**
     * Loads thumbnail image and sets image ratio
     */
    loadThumbnail() {
        if (this.thumbnailPath == null || this.thumbnailTexture != null)
            return;

        this.status = this.STATES.THUMBNAIL_LOADING;
        this.textureLoader.load(this.thumbnailPath, (texture) => {
            texture.encoding = THREE.sRGBEncoding;   
            this.thumbnailTexture = texture
            this.sizeRatio = texture.image.height / texture.image.width; 
            this.status = this.STATES.READY;
            if (this.app.carousel) { // update loaded lists            
                this.app.carousel.loadedThumbnails.push(this);
            }
            this.setSizeRatio();
            this.trigger('thumbnail_loaded');
        });
    }

    /**
     * Loads zoom image and sets image ratio
     */
    loadZoom() {
        if (this.zoomPath == null || this.zoomTexture != null)
            return;
        
        this.status = this.STATES.ZOOM_LOADING;
        this.loadingPlaceholderMesh.visible = true;
        this.textureLoader.load(this.zoomPath, (texture) => {
            texture.encoding = THREE.sRGBEncoding;   
            this.zoomTexture = texture;
            this.sizeRatio = texture.image.height / texture.image.width; 
            this.loadingPlaceholderMesh.visible = false;
            this.status = this.STATES.READY;
            this.setSizeRatio();
            this.trigger('zoom_loaded');
        });
    }

    changeToColor()
    {
        this.setSizeRatio();
        this.setMaterial();
        this.mesh.material = this.colorMaterial;
        this.viewState = this.VIEWSTATES.COLOR;
    }

    changeToThumbnail()
    {            
        if (this.thumbnailTexture) {
            this.setSizeRatio();
            if (this.thumbnailMaterial == null) {
                // create the material
                if (this.params.imageUseMask) {
                    this.thumbnailMaterial = new THREE.MeshBasicMaterial({       
                        map: this.thumbnailTexture,
                        side: THREE.DoubleSide,
                        alphaMap: this.resources.items.photomask,
                        transparent: true
                    });
                } else {
                    this.thumbnailMaterial = new THREE.MeshBasicMaterial({       
                        map: this.thumbnailTexture,
                        side: THREE.DoubleSide
                    });
                }
            }
            this.mesh.material = this.thumbnailMaterial;
            this.mesh.material.needsUpdate = true;
            this.viewState = this.VIEWSTATES.THUMBNAIL;
        }
    }

    changeToZoom() {
        //  this.viewState = this.VIEWSTATES.ZOOM;
        //  this.trigger('zoom_ready');
        if (this.zoomMaterial == null) {
            // material not ready

            if (this.zoomTexture == null) {
                // texture not ready, load it and call this function later
                this.on('zoom_loaded', () =>
                {
                    this.changeToZoom();
                });
                this.loadZoom()
                return;
            }

            this.setSizeRatio();

            this.zoomMaterial = new THREE.MeshBasicMaterial({       
                // color: "#FF0000", //debug
                map: this.zoomTexture
            }); 
            if (!this.params.fullImage.distortionDisable) {
                this.zoomMaterial.onBeforeCompile = (shader) =>
                {
                    shader.uniforms.time = this.zoomShaderUniforms.time;
                    shader.uniforms.progress = this.zoomShaderUniforms.progress;
                    shader.uniforms.displacemap = {value:this.resources.items.displaceTexture};
                    shader.vertexShader = shader.vertexShader.replace(
                        '#include <common>',
                        fullImageDistortionDeclarations
                    )
                    shader.fragmentShader = shader.fragmentShader.replace(
                        '#include <common>',
                        fullImageDistortionDeclarations
                    )
                    shader.fragmentShader = shader.fragmentShader.replace(
                        '#include <map_fragment>',
                        fullImageDistortionFragmentShader_Main
                    )
                }
            }
        }
        this.mesh.material = this.zoomMaterial;
        this.mesh.material.needsUpdate = true;

        this.viewState = this.VIEWSTATES.ZOOM;
        this.trigger('zoom_ready');
    }

    /**
     * Animate the hover effect (start: true/false to enter/exit animation)
     */
    hoverStartAnimation() {

        // fix size ratio, if lost
        this.setSizeRatio()

        this.status = this.STATES.ANIMATING;
        if (this.usualDistance == undefined)
            this.usualDistance = this.box.position.z;
        if (this.usualScale == undefined)
            this.usualScale = this.box.scale.x;
        // animate this image scale and position
        var hoverAnimationTL = gsap.timeline({
            duration: this.params.imageOnOverAnimationLength,
            ease: this.params.imageScaleOnOverEase,
            onComplete: () => {
                this.status = this.STATES.READY;
            }
        })
        if (this.params.imageScaleOnOver != 1) {
            hoverAnimationTL.to(this.box.scale, {    
                x: this.params.imageScaleOnOver*this.usualScale,
                y: this.params.imageScaleOnOver*this.usualScale,
                z: this.params.imageScaleOnOver*this.usualScale,         
            });    
        }
        hoverAnimationTL.to(this.box.position, {
            z: this.params.imageOnOverDistance
        },"<");

    }

    hoverEndAnimation() {

        // fix size ratio, if lost
        this.setSizeRatio()

        this.status = this.STATES.ANIMATING;
        
        // animate this image scale and position
        var hoverAnimationTL = gsap.timeline({
            duration: this.params.imageOnOverAnimationLength,
            ease: this.params.imageScaleOnOverEase,
            onComplete: () => {
                this.status = this.STATES.READY;
            }
        })
        if (this.params.imageScaleOnOver != 1) {
            hoverAnimationTL.to(this.box.scale, {    
                x: this.usualScale,
                y: this.usualScale,
                z: this.usualScale,         
            });    
        }
        hoverAnimationTL.to(this.box.position, {
            z: this.usualDistance
        },"<");
    }

    /**
     * Animate the displace effect
     */
    displaceAnimation() {
        if (this.params.fullImage.distortionDisable ||
            this.viewState != this.VIEWSTATES.ZOOM ||
            this.status == this.STATES.ANIMATING)
            return;

        this.status = this.STATES.ANIMATING;
        var tl = gsap.timeline({ 
            onComplete: () => {
                this.status = this.STATES.READY;
            }
        });
        tl.to(this.zoomShaderUniforms.progress, {
            delay: this.params.fullImage.distortionDelay,
            ease: this.params.fullImage.distortionEase,
            duration: this.params.fullImage.distortionLength,
            value: this.params.fullImage.distortionAmount
        })
        tl.to(this.zoomShaderUniforms.progress, {
            ease: this.params.fullImage.distortionEase,
            duration: this.params.fullImage.distortionLength,
            value: 0
        });
    }

    /**
     * checks if mouse is over the image
     */
    onMouseMove() {
        if (this.viewState == this.VIEWSTATES.ZOOM )
            return;
        
        if (this.app.carousel && this.app.carousel.status != this.app.carousel.STATES.THUMBNAILS) { 
            // avoid hover if not in thumbnails mode
            return;
        }

        if (this.pointer.activeMesh && this.pointer.activeMesh.carouselId == this.carouselId) {
            if (!this.mouseOver) {
                this.hoverStartAnimation();
                this.trigger("hover_start");
            }
            this.mouseOver = true;
        } else {
            if (this.mouseOver) {
                this.hoverEndAnimation();
                this.trigger("hover_end");
            }
            this.mouseOver = false;
        }
    }
    
    setSizeRatio() {
        // scale according to image ratio
        if (this.sizeRatio > 1) {
            this.mesh.scale.x = 1/this.sizeRatio;
        } else {
            this.mesh.scale.y = this.sizeRatio;
        }
    }

    /**
     * animation
     */
    update()
    {
        if (!this.params.fullImage.distortionDisable) {
            this.zoomShaderUniforms.time.value = this.app.time.elapsed/100;                
        }
    }

    destroy()
    {
        this.off('zoom_loaded');
    }

    rgbToHex(r, g, b) {
        return "#" + ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1);
    }
}