import * as THREE from 'three'
import Application from './Application.js'
import EventEmitter from './Utils/EventEmitter.js'
import { gsap } from "gsap";

export default class Intro extends EventEmitter
{
    constructor()
    {
        super();

        this.app = new Application()
        this.params = this.app.configuration.parameters;
        this.scene = this.app.scene
        this.resources = this.app.resources
        this.active = false;
        this.isActiveMeshIntro = false;

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
        this.enterAnimation();
    }

    setGeometry()
    {
        this.geometry = new THREE.CircleGeometry(this.params.intro.size, 64)
    }

    setMaterial()
    {
        this.textures = {}

        this.material = new THREE.MeshBasicMaterial({
            color: this.params.intro.color,
            alphaMap: this.resources.items.introOpacity,
            opacity: 0,
            transparent:true
        });

        if (this.resources.items.introimage) {
            this.material.map = this.resources.items.introimage
            this.material.map.encoding = THREE.sRGBEncoding
        }

    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.name = "intro";
        this.mesh.receiveShadow = false;
        this.mesh.castShadow = false;
        this.mesh.position.x = this.params.intro.startPosition.x;
        this.mesh.position.y = this.params.intro.startPosition.y;
        this.mesh.position.z = this.params.intro.startPosition.z;
        this.scene.add(this.mesh)

        this.app.pointer.meshesToCheck = new Array()
        this.app.pointer.meshesToCheck.push(this.mesh);
        this.app.pointer.on('move', () =>  { this.move() });
        this.app.pointer.on('click', () => { this.onClick() });
        this.active = true;
    }

    /**
     * Animation to show at start
     */
    enterAnimation() {
        gsap.to(this.material, {
            duration: this.params.intro.enterOpacityDuration,
            ease: this.params.intro.enterOpacityEase,
            opacity: this.params.intro.enterOpacityFinalValue
        })
        var verticalDevice = this.app.sizes.height / this.app.sizes.width > 1.6;

        gsap.fromTo(this.mesh.scale, {
            x: verticalDevice ? this.params.intro.enterScaleStartingFactorMobile : this.params.intro.enterScaleStartingFactor,
            y: verticalDevice ? this.params.intro.enterScaleStartingFactorMobile : this.params.intro.enterScaleStartingFactor,
            z: verticalDevice ? this.params.intro.enterScaleStartingFactorMobile : this.params.intro.enterScaleStartingFactor
        },{
            duration: this.params.intro.enterScaleDuration,
            ease: this.params.intro.enterScaleEase,
            x: verticalDevice ? this.params.intro.enterScaleEndingFactorMobile : this.params.intro.enterScaleEndingFactor,
            y: verticalDevice ? this.params.intro.enterScaleEndingFactorMobile : this.params.intro.enterScaleEndingFactor,
            z: verticalDevice ? this.params.intro.enterScaleEndingFactorMobile : this.params.intro.enterScaleEndingFactor
        })
    }

    move(e) {
        if (this.app.pointer.activeMesh && this.app.pointer.activeMesh.name == "intro") {
            if(!this.isActiveMeshIntro){
                document.getElementById('custom_cursor').className = 'link';
                this.isActiveMeshIntro = true;
            }
        }else {
            if(this.isActiveMeshIntro){
                document.getElementById('custom_cursor').className = 'default';
                this.isActiveMeshIntro = false;
            }
        }
    }

    onClick() {
        //evento logo cliccato
        if (!this.active)
        return;
        
        if (this.app.pointer.activeMesh && this.app.pointer.activeMesh.name == "intro") {
            document.body.dispatchEvent(new Event('click_logo'));
            
            this.active = false;

            gsap.to(this.mesh.position, {
                duration: this.params.intro.animationLength,
                ease: this.params.intro.animationEase,
                x: this.params.intro.endPosition.x,
                y: this.params.intro.endPosition.y,
                z: this.params.intro.endPosition.z,
                onComplete: () => {
                    this.scene.remove(this.mesh);
                    this.geometry.dispose();
                    this.material.dispose();
                    this.active = false;
                    this.trigger("ended");
                }
            });
        }
    }

}