import * as THREE from 'three'
import EventEmitter from './EventEmitter.js'

export default class Configuration extends EventEmitter
{
    /**
     * If provided with a file, tries to load it as JSON at runtime
     * If provided with an object, uses it directly
     */
    constructor(_config)
    {
        super()

        this.configItem = _config;
        this.parameters = {};
        this.loaded = false;

    }

    load() {
        if (typeof this.configItem === 'string' || this.configItem instanceof String) {
            // load parameters file at runtime
            this.loader = new THREE.FileLoader()
            this.loader.load(this.configItem, (obj) => {
                this.parameters = JSON.parse(obj)
                this.configLoaded()
            })    
        } else {
            // object is already a configuration
            this.parameters = Object.assign(this.configItem)
            this.configLoaded()
       }

    }

    configLoaded()
    {
        this.loaded = true;
        this.trigger('ready');
    }
}
        
