import * as THREE from 'three'
import Application from '../Application.js'
import Debug from '../Utils/Debug.js'

export default class Environment
{
    constructor()
    {
        this.app = new Application()
        this.scene = this.app.scene
        this.resources = this.app.resources
        this.params = this.app.configuration.parameters;
        this.debug = new Debug()

        if (!this.params.worldIsFlat) {
            this.setSunLight()
            if (this.params.environment.pointLightActive)
                this.setPointLight()
            this.setEnvironmentMap()
        }
    }

    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight(this.params.environment.sunLightColor, this.params.environment.sunLightIntensity);
        this.sunLight.castShadow = this.params.imageCastShadow;
        if (this.params.imageCastShadow) {
            this.sunLight.shadow.camera.near = this.params.environment.shadowNear
            this.sunLight.shadow.camera.far = this.params.environment.shadowFar
            this.sunLight.shadow.mapSize.set(this.params.environment.shadowMapSize,this.params.environment.shadowMapSize)
            this.sunLight.shadow.normalBias = this.params.environment.shadowNormalBias;
            this.sunLight.shadow.radius = this.params.environment.shadowBlurRadius;
            this.sunLight.shadow.camera.top = this.params.environment.shadowSide;
            this.sunLight.shadow.camera.right = this.params.environment.shadowSide;
            this.sunLight.shadow.camera.bottom = - this.params.environment.shadowSide;
            this.sunLight.shadow.camera.left = - this.params.environment.shadowSide;
        }
        this.sunLight.position.set(
            this.params.environment.sunLightPosition.x,
            this.params.environment.sunLightPosition.y,
            this.params.environment.sunLightPosition.z);
        this.scene.add(this.sunLight)

    }

    setPointLight()
    {
        this.pointLight = new THREE.PointLight(this.params.environment.pointLightColor, this.params.environment.pointLightIntensity, this.params.environment.pointLightDistance, this.params.environment.pointLightDecay);
        this.pointLight.castShadow = this.params.imageCastShadow && this.params.environment.pointLightShadows;
        if (this.pointLight.castShadow) {
            this.pointLight.shadow.camera.near = this.params.environment.shadowNear
            this.pointLight.shadow.camera.far = this.params.environment.shadowFar
            this.pointLight.shadow.mapSize.set(this.params.environment.shadowMapSize,this.params.environment.shadowMapSize)
            this.pointLight.shadow.normalBias = this.params.environment.shadowNormalBias;
            this.pointLight.shadow.radius = this.params.environment.shadowBlurRadius;
            this.pointLight.shadow.camera.top = this.params.environment.shadowSide;
            this.pointLight.shadow.camera.right = this.params.environment.shadowSide;
            this.pointLight.shadow.camera.bottom = - this.params.environment.shadowSide;
            this.pointLight.shadow.camera.left = - this.params.environment.shadowSide;
        }
        if (this.app.debug.active) {
            this.debug.addFolder("pointlight");
            this.debug.folders["pointlight"].add(this.pointLight,'intensity').listen();
            this.debug.folders["pointlight"].add(this.pointLight.position,'x').listen();
            this.debug.folders["pointlight"].add(this.pointLight.position,'y').listen();
            this.debug.folders["pointlight"].add(this.pointLight.position,'z').listen();
            const pointLightHelper = new THREE.PointLightHelper( this.pointLight, 1 );
            this.scene.add( pointLightHelper );
        }
        this.pointLight.position.set(
            this.params.environment.pointLightPosition.x,
            this.params.environment.pointLightPosition.y,
            this.params.environment.pointLightPosition.z);
        this.scene.add(this.pointLight)

    }

    setEnvironmentMap()
    {
        if (this.resources.items.environmentMapTexture == undefined)
            return;
            
        this.environmentMap = {}
        this.environmentMap.intensity = this.params.environment.environmentMapIntensity;
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding
        
        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterials = () =>
        {
            this.scene.traverse((child) =>
            {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }
        this.environmentMap.updateMaterials()
    }
}