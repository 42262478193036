import './style.css'
import Application from './Foglio/Application'
import UI from './UI';

const threeJSApp = new Application(document.querySelector('canvas.webgl'));

window.threeJSApp = threeJSApp;

document.addEventListener('DOMContentLoaded', () => {
    var ui = new UI();

    ui.setThreeJSApp(threeJSApp);

    threeJSApp.ui = ui;
});