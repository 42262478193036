import * as THREE from 'three'
import EventEmitter from './Utils/EventEmitter.js'
import Application from './Application.js'

export default class ImagesData extends EventEmitter
{
    constructor()
    {
        super()
        this.app = new Application();
        this.params = this.app.configuration.parameters;
        this.loader = new THREE.FileLoader()

        this.galleryType = null;
        this.galleryId = null;

        this.loaded = false;

        this.list = [];
    }

    /**
     * Adds images to the current list
     */
    load()
    {
        if (this.galleryType == null)
            this.galleryType = "home";
        
        // construct JSON query
        var query = this.params.imageQuery+this.galleryType;
        if (this.galleryType != "home")
            query += "/"+this.galleryId;
        
        //console.log(query);

        this.loader.load(query, (obj) => {
            // LOADED
            var imgs = JSON.parse(obj);
            //console.log(imgs);
            for (var i=0; i < imgs.imageList.length; i++) {
                this.list.push(imgs.imageList[i]);
            }
        
            // put random colors for now
            // for (var i=0; i < this.list.length; i++) {
            //     this.list[i].color = this.rgbToHex(Math.random()*256,Math.random()*256,Math.random()*256);
            // }
            this.loaded = true;
            this.trigger('loaded');
        }, ( xhr ) => {
            // PROGRESS
            // console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
        }, ( err ) => {
            //ERROR
            console.error( 'ERROR quering: ' +query);
            this.loaded = false;
            this.trigger('error');
        })
    }

    reset()
    {
        // console.log("reset list");
        this.loaded = false;
        this.list = [];
    }

    
    rgbToHex(r, g, b) {
        return "#" + ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1);
    }
}