import * as THREE from 'three'

import Sizes from './Utils/Sizes.js'
import Debug from './Utils/Debug.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Intro from './Intro.js'
import Resources from './Utils/Resources.js'
import Configuration from './Utils/Configuration.js';

import sources from './sources.js'
import config from './configuration.js'
import Pointer from './Pointer.js'
import Carousel from './Carousel.js'
import ColorDial from './World/ColorDial.js'
import ColorShutter from './World/ColorShutter.js'
import url from 'url-parameters';

let instanceApplication = null

export default class Application
{
    constructor(_canvas)
    {
        // Singleton
        if(instanceApplication)
        {
            return instanceApplication
        }
        instanceApplication = this

        // Global access
        window.app = this

        // Options
        this.canvas = _canvas
        this.sizes = new Sizes()
        this.time = new Time()
        
        // Load configuration
        this.configuration = new Configuration(config);
         // Wait for config loading
        this.configuration.on('ready', () =>
        {
            this.debug = new Debug(this.configuration.parameters.debug)

            // Setup
            this.scene = new THREE.Scene()
            this.resources = new Resources(sources)
            // Wait for resources
            this.resources.on('ready', () =>
            {
                this.pointer = new Pointer()
                this.camera = new Camera()
                this.pointer.camera = this.camera;

                this.renderer = new Renderer()
                this.world = new World()

                if (url.get('gt') == null || url.get('gt') == "home") {
                    // home: show intro
                    this.intro = new Intro()
                    this.intro.on("ended", () => {
                        this.startMainInterface();
                    });
                } else {
                    // don't use intro
                    this.startMainInterface();
                }
            });
            this.params = this.configuration.parameters; // for easier call
        });
        this.configuration.load();

        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () =>  {
            this.update()
        })

    }

    /**
     * creates the main interface, after the intro ends
     */
    startMainInterface() {
        if (this.params.logoHide)
            this.colorDial = new ColorShutter();    // use new color shutter
        else
            this.colorDial = new ColorDial();       // use old color dial
        this.colorDial.close(0);
        this.colorDial.on("opened", () => {this.colorDialChanged()});
        this.colorDial.on("closed", () => {this.colorDialChanged()});

        this.carousel = new Carousel(this.world.instance)
        this.carousel.inclineOnZoom = [this.world.floor.mesh]; // set the objects to incline when zoom
        if (this.params.logoInclineOnImageDeactivation) {
            if (this.params.logoHide)
                this.carousel.inclineOnZoom.push(this.world.logo.mesh);
            else
                this.carousel.inclineOnZoom.push(this.colorDial.mesh);
        }
        this.carousel.active = false; // wait before mouse input can be sent to carousel

        // inform the pointer we need to check clicks on logo or color dial sectors
        this.pointer.meshesToCheck = new Array()
        if (this.params.logoHide)
            this.pointer.meshesToCheck.push(this.colorDial.logo,...this.colorDial.meshes); 
        else
            this.pointer.meshesToCheck.push(this.world.logo.mesh,...this.colorDial.meshes); 

        this.carousel.on('shuffled', () =>  { this.onCarouselShuffled()  });
        this.carousel.on('loaded', () =>  { this.onCarouselLoaded()  });
        this.carousel.on('error', () =>  { this.onCarouselError()  });
        this.pointer.on('move', () => { this.onMove() });
        this.pointer.on('click', () => { this.onClick() });

        document.addEventListener('keydown', (e) => { this.onKeyDown(e) },true);
    }

    onMove() {
        if (this.pointer.lastActiveMesh2 == this.pointer.activeMesh)
            return;
        
        if (this.pointer.activeMesh == null) {
            this.ui.cursorToDefault();
        }else{
            if(this.pointer.activeMesh.name == "logo" || this.pointer.activeMesh.name.includes('colorDial_'))
                this.ui.cursorToDial();
        }

        this.pointer.lastActiveMesh2 = this.pointer.activeMesh;
    }

    /**
     * called when user click
     */
    onClick() {
        if (!this.pointer.activeMesh || !this.carousel.pointer.active || this.carousel.zoomedItemId != null)
            return;
           
        if (this.pointer.activeMesh.name == "logo") {
            // logo clicked: toggle dial visibility
            this.colorDial.toggle();
        }

        if (this.pointer.activeMesh.name.startsWith("colorDial_") && this.pointer.activeMesh.colorDialId >= 0) {
            // color dial clicked
            var colId = this.pointer.activeMesh.colorDialId;
            if (this.colorDial.links[colId].galleryType !== undefined) {
                
                // if the gallery is the same, close and do nothing
                if (this.colorDial.links[colId].galleryType == this.carousel.imagesData.galleryType && this.colorDial.links[colId].galleryId == this.carousel.imagesData.galleryId) {
                    this.carousel.active = true;
                    this.colorDial.close()
                    return;
                }
                
                // go to gallery
                this.carousel.active = false;
                this.colorDial.close()
                url.apply({
                    gt: this.colorDial.links[colId].galleryType,
                    gid: this.colorDial.links[colId].galleryId
                });
            }
        }

        // the other click events are handled by objects (carousel, images...)
    }

    /**
     * when dial open / close, enable disable interaction
     */
    colorDialChanged() {
        // enable/disable functions of carousel
        if (this.colorDial.opened)
            this.carousel.active = false;
        else
            this.carousel.active = true;
    }

    /**
     * called when carousel gallery has just loaded new images
     */
    onCarouselLoaded() {
        this.colorDial.peep();

        var carouselLoaded = new Event('carousel_loaded');

        document.body.dispatchEvent(carouselLoaded);
    }

    /**
     * called when carousel gallery is updated with new images
     */
    onCarouselShuffled() {
        this.carousel.active = true;
    }

    /**
     * called when carousel gallery has an error 
     */
    onCarouselError() {
        // go to home
        window.location.href="";
    }

    /**
     * keyboard
     */
    onKeyDown(e) {
        switch(e.which) {
            case 27:   // ESC
                this.carousel.zoomRequestHandler();
                break;
            case 37: // right left
                this.carousel.goToPrevImage();
                break;
            case 39: // arrow right    
                this.carousel.goToNextImage();
                break;
        }
    }

    resize() {
        if (this.configuration.loaded) {
            this.camera.resize()
            this.renderer.resize()
        }
    }

    update() {
        if (this.world && this.configuration.loaded) {
            this.camera.update()
            this.world.update()   
            if (this.carousel)
                this.carousel.update() 
            this.renderer.update()
       }
    }

    destroy()  {
        this.sizes.off('resize')
        this.time.off('tick')
        this.carousel.off('shuffled')

        // Traverse the whole scene
        this.scene.traverse((child) =>    {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)    {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)   {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                        value.dispose();
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()
        this.debug.dispose()
    }
}