import SplitText from "./Lib/SplitText.min";
import gsap from "gsap";
import Hammer from './Lib/hammer.min'
import url from 'url-parameters';

export default class UI {
    constructor(){
        this.init();

        window.gsap = gsap;
        window.SplitText = SplitText;

        this.custom_cursor = document.getElementById('custom_cursor');
    }

    init() {
        var _this = this;

        this.setInitialStyle();

        this.setThreeJSApp(threeJSApp);

        this.init_custom_cursor();
        
        this.init_link_cursor();
        
        this.init_control_shade();
        
        this.init_canvas_UI_listeners();
        
        //se su pagina interna lancia animazione
        if(url.get('gt') == 'home' || (url.get('gid') == null && url.get('id') == null ))
            document.body.addEventListener('click_logo', this.animazione_ingresso_nav_elements);
        else
            this.animazione_ingresso_nav_elements();
    }

    setInitialStyle() {
        gsap.set('#nav', { zIndex: -10 })
    }

    animazione_ingresso_nav_elements() {
        var _this = this;

        var isTouchDevice = () => {
            return (('ontouchstart' in window) ||
               (navigator.maxTouchPoints > 0) ||
               (navigator.msMaxTouchPoints > 0));
        }

        gsap.set('#nav', { zIndex: 1 })
        
        //animazione ingresso nav links
        var nav_label_split = new SplitText('.nav_label', { type: 'chars'});

        gsap.timeline({ delay: 1.5 })
            .set(nav_label_split.chars, { y: 50 })
            .set("#nav", { opacity: 1 })
            .to(nav_label_split.chars, { 
                y:0,
                duration: 0.5, // seconds
                ease: "power4.in",
                stagger: 0.05, // stagger start times
            });


        //init_animazione_help
        var help_p_split = new SplitText('#help-wrapper p', { type: 'lines' });

        //aggiungo contro-container ad ogni riga per effetto reveal
        help_p_split.lines.forEach((line)=> {
            line.className = 'help-outerLine';
            line.innerHTML = '<div class="help-innerLine">' + line.innerHTML + '</div>';
        })

        gsap.set("#help-wrapper", { display: 'none' })
        gsap.set("#help-wrapper", { clearProps: 'opacity' })

        this.tl_help = gsap.timeline({ paused: true })
            .set("#help-wrapper", { display: 'block' })
        //label non scompare se device è touch
        if(!isTouchDevice()) {
            this.tl_help
            .fromTo("#help .nav_label > div", {
                y: 0
            },
            {
                y: 45,
                duration: .2,
                stagger: .03
            })
        }
        
        this.tl_help.fromTo('.help-innerLine', {
            y: 75
        },
        {
            y: 0,
            duration: .2,
            ease: 'power2.out',
            stagger: .03
        }, '<.1')

        //init_animazione_about
        var about_p_split = new SplitText('#about-wrapper p', { type: 'lines' });

        //aggiungo contro-container ad ogni riga per effetto reveal
        about_p_split.lines.forEach((line)=> {
            line.className = 'about-outerLine';
            line.innerHTML = '<div class="about-innerLine">' + line.innerHTML + '</div>';
        })

        gsap.set("#about-wrapper", { display: 'none' })
        gsap.set("#about-wrapper", { clearProps: 'opacity' })

        this.tl_about = gsap.timeline({ paused: true })
            .set("#about-wrapper", { display: 'block' })
        //label non scompare se device è touch
        if(!isTouchDevice()) {
            this.tl_about
            .fromTo("#about .nav_label > div", {
                y: 0
            },
            {
                y: 45,
                duration: .2,
                stagger: .03
            })
        }
        
        this.tl_about.fromTo('.about-innerLine', {
            y: 75
        },
        {
            y: 0,
            duration: .2,
            ease: 'power2.out',
            stagger: .03
        }, '<.1')

            //init_animazione_contact() {
        var contact_p_split = new SplitText('#contact-wrapper p', { type: 'lines' });

        //aggiungo contro-container ad ogni riga per effetto reveal
        contact_p_split.lines.forEach((line)=> {
            line.className = 'contact-outerLine';
            line.innerHTML = '<div class="contact-innerLine">' + line.innerHTML + '</div>';
        })

        gsap.set("#contact-wrapper", { display: 'none' })
        gsap.set("#contact-wrapper", { clearProps: 'opacity' })

        this.tl_contact = gsap.timeline({ paused: true })
            .set("#contact-wrapper", { display: 'block' })
        
        //label non scompare se device è touch
        if(!isTouchDevice()) {
            this.tl_contact
            .fromTo("#contact .nav_label > div", {
                y: 0
            },
            {
                y: 25,
                duration: .2,
                stagger: .03
            })
        }

        this.tl_contact.fromTo('.contact-innerLine', {
            y: 45
        },
        {
            y: 0,
            duration: .2,
            ease: 'power2.out',
            stagger: {
                each: .03,
                from: 'end'
            }
        }, '<.1')

        //eventi su help
        var nav_help = document.getElementById('help');
        nav_help.onmouseover = () => { _this.tl_help.play() }
        nav_help.onmouseout = () => { _this.tl_help.reverse() }

        //eventi su about
        var nav_about = document.getElementById('about');
        nav_about.onmouseover = () => { _this.tl_about.play() }
        nav_about.onmouseout = () => { _this.tl_about.reverse() }

        //eventi su contact
        var nav_contact = document.getElementById('contact');
        nav_contact.onmouseover = () => { _this.tl_contact.play() }
        nav_contact.onmouseout = () => { _this.tl_contact.reverse() }

    }

    setThreeJSApp(threeJSApp) {
        this.threeJSApp = threeJSApp;
    }

    init_custom_cursor() {
        const custom_cursor = document.getElementById('custom_cursor');
        const help = document.getElementById('help');

        var threshold = window.innerWidth * 3 / 4;
        var status = false;

        const moveCursor = (e)=> {
            custom_cursor.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;

            if((e.clientX >= threshold) != status) {
                status = !status;
                help.style.display = status ? 'block' : 'none'
            }
        }

        //movimento
        window.addEventListener('mousemove', moveCursor);
    }

    cursorToArrowRight(e) {
        //cursor freccia solo se non è alla fine della lista
        if(threeJSApp.carousel.carouselId != threeJSApp.carousel.imagesData.list.length - 1)
            custom_cursor.className = 'arrow-right';
        else
            custom_cursor.className = 'default';
    }

    cursorToArrowLeft(e) {
        //cursor freccia solo se non è all'inizio della lista
        if(threeJSApp.carousel.carouselId != 0)
            custom_cursor.className = 'arrow-left';
        else
            custom_cursor.className = 'default';
    }

    cursorToImage(e) {
        custom_cursor.className = 'image';
    }

    cursorToDot(e) {
        custom_cursor.className = 'dot';
    }

    cursorToLink(e) {
        custom_cursor.className = 'link';
    }

    cursorToSecondLink(e) {
        custom_cursor.className = 'second-link';
    }

    cursorToDial(e) {
        custom_cursor.className = 'dial';
    }

    cursorToDefault(e) {
        custom_cursor.className = 'default';
    }

    init_link_cursor() {
        document.querySelectorAll('#contact, #about').forEach((el) => {
            el.addEventListener('mouseleave', this.cursorToDefault);
            el.addEventListener('mouseenter', this.cursorToDot);
        });

        document.querySelectorAll('#portfolio, #home, #contact-wrapper a, #mail, #help, #link, #ensoul').forEach((el) => {
            el.addEventListener('mouseleave', this.cursorToDefault);
            el.addEventListener('mouseenter', this.cursorToLink);
        });
    }
    
    init_control_shade() {
        var _this = this;

        const control_shade_left = document.getElementById('control-shade__left');
        const control_shade_right = document.getElementById('control-shade__right');
        const control_shade_center = document.getElementById('control-shade__center');
        
        control_shade_left.addEventListener('mouseleave', this.cursorToDefault);
        control_shade_left.addEventListener('mouseenter', this.cursorToArrowLeft);
        
        control_shade_center.addEventListener('mouseenter', this.cursorToDefault);
        
        control_shade_right.addEventListener('mouseleave', this.cursorToDefault);
        control_shade_right.addEventListener('mouseenter', this.cursorToArrowRight);


        var is_swiping = false;

        var control_shade = document.getElementById('control-shade');
        var control_shade__close = document.getElementById('control-shade__center');


        var canvas = document.querySelector('canvas.webgl');
        
        //dispatch su canvas dell'evento mousemove
        control_shade.addEventListener('mousemove', (e) => {
            canvas.dispatchEvent(new MouseEvent('mousemove', { bubbles: false, clientX: e.clientX, clientY: e.clientY, view: window }));
        });
        
        //dispatch su canvas dell'evento wheel
        control_shade.addEventListener('wheel', (e) => {
            canvas.dispatchEvent(new WheelEvent('wheel', { bubbles: false, deltaY: e.deltaY }));
        })

        //click su parte centrale della shade chiude la control_shade
        control_shade__close.addEventListener('click', (e) => {
            if(is_swiping) return;

            this.threeJSApp.carousel.zoomRequestHandler();
        })

        control_shade_left.addEventListener('click', (e) => { e.stopPropagation(); _this.threeJSApp.carousel.goToPrevImage(); _this.cursorToArrowLeft();  });
        control_shade_right.addEventListener('click', (e) => { e.stopPropagation(); _this.threeJSApp.carousel.goToNextImage(); _this.cursorToArrowRight(); });
        
        var mc = new Hammer(control_shade, {recognizers: [ 
            [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
            [Hammer.Pinch, { enable: true }],
        ]});


        mc.on("swipeleft", (ev) => {
            ev.srcEvent.stopPropagation();
            is_swiping = true;
            setTimeout(() => { is_swiping = false }, 50)
            this.threeJSApp.carousel.goToNextImage();
        });
        
        mc.on("swiperight", (ev) => {
            ev.srcEvent.stopPropagation();
            is_swiping = true;
            setTimeout(() => { is_swiping = false }, 50)
            this.threeJSApp.carousel.goToPrevImage();
        });

        var scaleRefValue,xRefValue, yRefValue;

        mc.on("pinchstart", (ev) => {
            scaleRefValue = this.threeJSApp.carousel.zoomScaleRatio;
            xRefValue = 0;
            yRefValue = 0;
        })

        mc.on("pinchmove", (ev) => {
            //var debug_output = document.getElementById('debug_output');
            //debug_output.innerHTML = newScaleRatio //+ '<br><br>------<br><br>';

            if (this.threeJSApp.carousel.status == this.threeJSApp.carousel.STATES.ZOOM) {            
                if (this.threeJSApp.carousel.zoomedItemId != null && this.threeJSApp.carousel.imagesData.list[this.threeJSApp.carousel.zoomedItemId] != undefined) {
                    var ci = this.threeJSApp.carousel.imagesData.list[this.threeJSApp.carousel.zoomedItemId].carouselImage;
                    var newScaleRatio = Math.max(scaleRefValue + scaleRefValue * (ev.scale - 1), 1);
                    newScaleRatio = Math.min(newScaleRatio, 8);
                    
                    //update scale
                    ci.box.scale.x = ci.box.scale.y = ci.box.scale.z = newScaleRatio;

                    //update position
                    var ci = this.threeJSApp.carousel.imagesData.list[this.threeJSApp.carousel.zoomedItemId].carouselImage;
                    ci.mesh.position.x = ci.mesh.position.x + (ev.deltaX - xRefValue) * 0.005;
                    ci.mesh.position.y = ci.mesh.position.y - (ev.deltaY - yRefValue) * 0.005;

                    //save ref value
                    xRefValue = ev.deltaX;
                    yRefValue = ev.deltaY;
                    this.threeJSApp.carousel.zoomScaleRatio = newScaleRatio;
                }
            }
        })
    }

    init_canvas_UI_listeners() {
        document.body.addEventListener('carousel_loaded', () => {
            //effetto hover del cursor su immagini
            threeJSApp.carousel.imagesData.list.forEach((image) => {
                image.carouselImage.on('hover_end', () => { setTimeout(this.cursorToDefault, 10); })
                image.carouselImage.on('hover_start', () => { setTimeout( this.cursorToImage, 25); })
            });

            //effetto hover del cursor su didascalie
            var longDescription = document.querySelector('.longDescription')
        /*     longDescription.onmouseover = this.cursorToOnDidascalia
            longDescription.onmouseout = this.cursorToDefault */

            longDescription.addEventListener('longdescription_on', () => {
                var anchor = longDescription.querySelector('a');

                if(anchor) {
                    anchor.onmouseover = this.cursorToSecondLink
                    anchor.onmouseout = this.cursorToDefault
                }
            })
        })
    }
}