import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter
{
    constructor()
    {
        super()
        
        // Setup
        this.resized();

        // Resize event
        window.addEventListener('resize', () =>
        {
            this.resized();
            this.trigger('resize')
        })
    }


    resized() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.pixelRatio = Math.min(window.devicePixelRatio, 2);
    }
}