import Application from '../Application.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Logo from './Logo.js'

export default class World
{
    constructor()
    {
        this.app = new Application()
        this.scene = this.app.scene
        this.resources = this.app.resources
        
        // Setup
        this.floor = new Floor()
        // this.logo = new Logo();
        this.environment = new Environment()
    }

    update()
    {
        if (this.carousel)
            this.carousel.update();
        if (this.floor)
            this.floor.update();
    }
}