import * as THREE from 'three'
import EventEmitter from './EventEmitter.js'
import Debug from './Debug.js'

export default class Accelerometer extends EventEmitter
{
    constructor()
    {
        super()

        this.debug = new Debug()

        this.active = true;
        
        this.deviceOrientation = {}; // alpha beta gamma of device orientation
        this.acceleration = {};     // x y z acceleration
        this.rotationRate = {};     // alpha beta gamma rotation acceleration
        // alpha: The rate at which the device is rotating about its Z axis; that is, being twisted about a line perpendicular to the screen.
        // beta: The rate at which the device is rotating about its X axis; that is, front to back.
        // gamma: The rate at which the device is rotating about its Y axis; that is, side to side.
	    this.screenOrientation = 0;

        this.alpha = 0;
        this.beta = 0;
        this.gamma = 0;
        this.alphaOffsetAngle = 0;
        this.betaOffsetAngle = 0;
        this.gammaOffsetAngle = 0;
        this.quaternion = new THREE.Quaternion();
        
        this.onOrientationChange(); 

        window.addEventListener('deviceorientation', (e) => {       
            this.onDeviceOrientation(e);
        });

        window.addEventListener('orientationchange', (e) => { 
            this.onOrientationChange(e) 
        });

        window.addEventListener('devicemotion', (e) => {       
            this.onDeviceMotion(e);
            this.trigger("motion");
        });

        if (this.debug.active) {
            this.debug.addFolder("Orientation");
            this.debug.folders["Orientation"].add(this,"screenOrientation").listen();
            this.debug.folders["Orientation"].add(this,"alpha").listen();
            this.debug.folders["Orientation"].add(this,"beta").listen();
            this.debug.folders["Orientation"].add(this,"gamma").listen();
            this.debug.folders["Orientation"].add(this.quaternion,"x").listen();
            this.debug.folders["Orientation"].add(this.quaternion,"y").listen();
            this.debug.folders["Orientation"].add(this.quaternion,"z").listen();
            this.debug.folders["Orientation"].add(this.quaternion,"w").listen();
        }

    }

    onDeviceOrientation(event) {
        this.deviceOrientation = event;
    }
    onOrientationChange(event) {
        this.screenOrientation = window.orientation || 0;
    }
    onDeviceMotion(event) {
        this.rotationRate = event.rotationRate;
        this.acceleration = event.acceleration;
    }

    update() {
        if (!this.active) return;
		this.alpha = this.deviceOrientation.alpha ? THREE.Math.degToRad( this.deviceOrientation.alpha ) + this.alphaOffsetAngle : 0; // Z
		this.beta = this.deviceOrientation.beta ? THREE.Math.degToRad( this.deviceOrientation.beta ) + this.betaOffsetAngle : 0; // X'
		this.gamma = this.deviceOrientation.gamma ? THREE.Math.degToRad( this.deviceOrientation.gamma ) + this.gammaOffsetAngle : 0; // Y''
		var orient = this.screenOrientation ? THREE.Math.degToRad( this.screenOrientation ) : 0; // O

		this.setObjectQuaternion( orient );
    }
    updateAlphaOffsetAngle( angle ) {
		this.alphaOffsetAngle = angle;
		this.update();
	}
    updateBetaOffsetAngle( angle ) {
		this.betaOffsetAngle = angle;
		this.update();
	}
    updateGammaOffsetAngle( angle ) {
		this.gammaOffsetAngle = angle;
		this.update();
	}

    setObjectQuaternion( orient) {
		var zee = new THREE.Vector3( 0, 0, 1 );
		var euler = new THREE.Euler();
		var q0 = new THREE.Quaternion();
		var q1 = new THREE.Quaternion( - Math.sqrt( 0.5 ), 0, 0, Math.sqrt( 0.5 ) ); // - PI/2 around the x-axis

		euler.set( this.beta, this.alpha, - this.gamma, 'YXZ' ); // 'ZXY' for the device, but 'YXZ' for us
        this.quaternion.setFromEuler( euler ); // orient the device
        this.quaternion.multiply( q1 ); // camera looks out the back of the device, not the top
        this.quaternion.multiply( q0.setFromAxisAngle( zee, - orient ) ); // adjust for screen orientation
	}

    dispose() {
    }
}