import EventEmitter from './Utils/EventEmitter.js'
import * as THREE from 'three'
import Application from './Application.js'

export default class Pointer extends EventEmitter
{
    constructor(_app)
    {
        super()
       
        // Setup
        this.app = _app ?? new Application()
        this.params = this.app.configuration.parameters;
        this.sizes = this.app.sizes
        this.camera = this.app.camera;

        this.raycaster = new THREE.Raycaster();

        this.mouse = new THREE.Vector2();
        this.mouse2D = new THREE.Vector2();

        this.previousMouse2D = new THREE.Vector2(0,0);
        this.mouseSpeed = new THREE.Vector2();
        this.dragging = false;
        this.dragDistance = 0;

        this.pinch = false;
        this.pinchDistanceEnd = 0;
        this.pinchDistanceStart = 0;

        this.active = true; // you can deactivate input check (mouse movement will be calculated anyway)

        this.meshesToCheck = [] // array of meshes to check for raycasting

        this.activeImage = null;  
        this.activeImageId = null;       
        this.lastActiveImage = null;
        this.lastActiveImageId = null;

        //canvas
        var canvas = document.querySelector('canvas.webgl');

        // Click/touch event
        canvas.addEventListener('click', (e) =>
        {            
            this.locateMouseEvent(e);
            this.trigger('click')
        });

        // Move event
        canvas.addEventListener('mousemove', (e) => { this.onMouseMove(e) },true);
        canvas.addEventListener('touchmove', (e) => { this.onMouseMove(e) },true);

        // Drag events
        canvas.addEventListener('touchstart', (e) => { this.onMouseDown(e) },true);
        canvas.addEventListener('mousedown', (e)  => { this.onMouseDown(e) },true);
        canvas.addEventListener('touchend', (e) => { this.onMouseUp(e) },true);
        canvas.addEventListener('touchcancel', (e) => { this.onMouseUp(e) },true);
        canvas.addEventListener('mouseup', (e) => { this.onMouseUp(e) },true);
        canvas.addEventListener('wheel', (e) => { this.onMouseWheel(e) },true);
        
    }

    onMouseMove(e) {
        this.locateMouseEvent(e);
        if ( this.pinch && e.touches && e.touches.length == 2) { // pinch
            var dx = e.touches[ 0 ].clientX - e.touches[ 1 ].clientX;
            var dy = e.touches[ 0 ].clientY - e.touches[ 1 ].clientY;
            this.pinchDistanceEnd = Math.sqrt( dx * dx + dy * dy );

            this.trigger('pinch_change')
        }
        this.trigger('move')
    }
    onMouseDown(e) {
        this.dragging = true;
        this.pinch = false;
        if ( e.touches && e.touches.length == 2) { // pinch
            this.pinch = true;
            var dx = e.touches[ 0 ].clientX - e.touches[ 1 ].clientX;
            var dy = e.touches[ 0 ].clientY - e.touches[ 1 ].clientY;
            this.pinchDistanceEnd = this.pinchDistanceStart = Math.sqrt( dx * dx + dy * dy );
        }
        this.mouseDragDistance = 0;
        this.trigger('down')
    }
    onMouseUp(e) {
        this.dragging = false;
        this.pinchDistanceEnd = this.pinchDistanceStart = 0;
        this.pinch = false;
        this.mouseDragDistance = 0;
        this.trigger('up');
    }
    onMouseWheel(e) {
        // simulate pinch
        if (e.deltaY < 0) {
            this.pinchDistanceEnd = 1;
            this.pinchDistanceStart = -e.deltaY * this.params.fullImage.scaleRatioWheelFactor;    
        } else {
            this.pinchDistanceEnd = e.deltaY * this.params.fullImage.scaleRatioWheelFactor;
            this.pinchDistanceStart = 1;    
        }
        this.trigger('pinch_change')
    }

    // find mouse position
    locateMouseEvent(e)
    {
        var mouseX = e.clientX ?? e.touches[0].clientX
        var mouseY =e.clientY ?? e.touches[0].clientY

        this.mouse.x = mouseX;
        this.mouse.y = mouseY;
        if (this.dragging)
            this.dragDistance += this.previousMouse2D.distanceTo(this.mouse2D);
        else
            this.dragDistance = 0;
        this.previousMouse2D = this.mouse2D.clone();
        this.mouse2D.x = (this.mouse.x / this.sizes.width) * 2 - 1;
        this.mouse2D.y = -(this.mouse.y / this.sizes.height) * 2 + 1;
        this.mouseSpeed.x = this.mouse2D.x-this.previousMouse2D.x;
        this.mouseSpeed.y = this.mouse2D.y-this.previousMouse2D.y;
    
        // find a mesh under the mouse
        if (this.camera && this.active && this.meshesToCheck.length > 0) {
            // if we have data
            // save the previous active mesh, if any
            this.lastActiveMesh = this.activeMesh;
            //reset active mesh
            this.activeMesh = null;
            
            //find a new clicked mesh
            this.raycaster.setFromCamera(this.mouse2D, this.camera.instance);

            var intersects = this.raycaster.intersectObjects(this.meshesToCheck);
            if (intersects.length > 0) {
                this.activeMesh = intersects[0].object;    
            }
            
        }
    }
    isTouchDevice() {
        var isTouchDevice = (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));

        console.log(isTouchDevice);
        return isTouchDevice;
    }
}