import * as THREE from 'three'
import Application from '../Application.js'
import EventEmitter from '../Utils/EventEmitter.js'
import { gsap } from "gsap";
import { TimelineMax } from 'gsap/gsap-core.js';

export default class ColorDial extends EventEmitter
{
    constructor(opened = true)
    {
        super();

        this.app = new Application()
        this.params = this.app.configuration.parameters;
        this.scene = this.app.scene
        this.resources = this.app.resources;
        this.opened = opened;
        this.animating = false;

        // get colors and links from configuration
        this.colors = Array(this.params.colorDial.colors.length);
        this.links = Array(this.params.colorDial.colors.length);
        for (const i in this.params.colorDial.colors) {
            this.colors[i] = this.params.colorDial.colors[i][0];
            this.links[i] = {
                galleryType: this.params.colorDial.colors[i][1],
                galleryId: this.params.colorDial.colors[i][2]
            };
        }

        this.setGeometry()
        this.setMaterial()
        this.setMesh()

        if (this.params.colorDial.peepInterval > 0) {
            this.peepAnimation = new TimelineMax({
                duration:this.params.colorDial.peepInterval,
                repeat:-1,
                onRepeat:() => {
                    this.peep();
                }});
        }
    }

    setGeometry() {
        this.sectorCount = this.colors.length;
        this.sectorWidth = Math.PI*2 / this.sectorCount; // sector size according to number of colors
        this.sectorInnerWidth = this.sectorWidth*(1-this.params.colorDial.gapPercent);
        
        this.geometries = Array(this.sectorCount);

        for(const i in this.colors) {
            this.geometries[i] = new THREE.RingGeometry( 
                this.params.colorDial.innerRadius,
                this.params.colorDial.outerRadius,
                32,2,
                0, this.sectorInnerWidth);
        }
    }

    setMaterial() {
        this.materials = Array(this.sectorCount);

        for(const i in this.colors) {
            this.materials[i] = new THREE.MeshBasicMaterial({
                color: this.colors[i],                
            })
            if (this.resources.items.colorDialOpacity !== undefined) {
                this.materials[i].alphaMap = this.resources.items.colorDialOpacity;
                this.materials[i].transparent = true;
            }
        }
    }

    setMesh() {
        this.meshes = Array(this.sectorCount);
        this.mesh = new THREE.Group();

        for(const i in this.colors) {
            this.meshes[i] = new THREE.Mesh(this.geometries[i], this.materials[i])
            this.meshes[i].name = "colorDial_"+i;
            this.meshes[i].colorDialId = i;
            this.meshes[i].castShadow = this.params.imageCastShadow;
            this.meshes[i].receiveShadow = false;
            
            this.meshes[i].rotation.z = i * this.sectorWidth;
            
            this.mesh.add(this.meshes[i]);
        }    
        
        this.mesh.position.z = this.params.colorDial.floorDistance;    
        if (!this.opened) {
            // in case it's closed, scale immeditely to 0
            this.mesh.scale.x = this.mesh.scale.y = this.mesh.scale.z = 0;
        }  

        this.scene.add(this.mesh)
    }
    
    /**
     * animate and open or close. parameter is animation time.
     * If null, uses configuration time
     */
    close(t) {
        var time = t ?? this.params.colorDial.closeDuration;
        this.animating = true;
        gsap.to(this.mesh.scale, { // close animation
            duration: time,
            ease: this.params.colorDial.closeEase,
            x: 0,
            y: 0,
            onComplete:()=>{
                this.animating = false;
                this.opened = false;
                this.trigger("closed");
            }           
        }); 
    }

    open(t) {
        var time = t ?? this.params.colorDial.openDuration;
        this.animating = true;
        gsap.to(this.mesh.scale, { // open animation
            duration: time,
            ease: this.params.colorDial.openEase,
            x: 1,
            y: 1,
            onComplete:()=>{
                this.animating = false;
                this.opened = true;
                this.trigger("opened");
            }         
        }); 
    }

    /**
     * show half of the wheel for a moment
     */
    peep() {    
        if (!this.opened && !this.animating) {
            this.animating = true;
            var tl = gsap.timeline({onComplete:()=>{
                this.animating = false;
            }});
            tl.set(this.mesh.scale,{
                x: this.params.colorDial.peepStartScale,
                y: this.params.colorDial.peepStartScale
            });
            tl.to(this.mesh.scale, { // half open animation
                duration: this.params.colorDial.peepDuration/2,
                ease: this.params.colorDial.peepEase,
                x: this.params.colorDial.peepEndScale,
                y: this.params.colorDial.peepEndScale
            });
            tl.to(this.mesh.scale, { // half open animation
                duration: this.params.colorDial.peepDuration/2,
                ease: this.params.colorDial.peepEase,
                x: this.params.colorDial.peepStartScale,
                y: this.params.colorDial.peepStartScale  
            });
            tl.set(this.mesh.scale,{
                x: 0,
                y: 0
            });
        }
    }

    toggle() {
        if (this.animating)
            return;
        
        if (this.opened)
            this.close();
        else
            this.open();
    }

    update() {
        
    }
}